import React from "react";
import { Source, Layer } from "react-map-gl";

export default function Routes() {
  const routesLine = {
    id: "routes-line",
    type: "line",
    beforeId: "fixes",

    paint: {
      "line-width": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        0.25,
        5,
        0.25,
        9,
        1,
      ],
      "line-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        ["match", ["get", "category"], ["OCEANIC"], 1, 0],
        5,
        ["match", ["get", "category"], ["OCEANIC"], 1, 0],
        9,
        ["match", ["get", "category"], ["OCEANIC"], 1, 1],
      ],
    },
  };
  const routesSymbol = {
    id: "routes-symbol",
    type: "symbol",
    beforeId: "fixes",
    layout: {
      "icon-image": [
        "match",
        ["get", "direction"],
        ["BACKWARD", "FORWARD"],
        "route-designator-oneway",
        "route-designator-twoway",
      ],
      "icon-rotate": ["match", ["get", "direction"], ["BACKWARD"], 180, 0],
      "icon-size": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        ["match", ["get", "category"], ["OCEANIC"], 0.5, 0],
        5,
        ["match", ["get", "category"], ["OCEANIC"], 1, 0],
        9,
        ["match", ["get", "category"], ["OCEANIC"], 1.25, 1.25],
      ],
      "symbol-placement": "line",
      "text-field": "{designator}",
      "text-size": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        ["match", ["get", "category"], ["OCEANIC"], 5, 0],
        5,
        ["match", ["get", "category"], ["OCEANIC"], 8, 0],
        9,
        ["match", ["get", "category"], ["OCEANIC"], 10, 10],
      ],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    },

    paint: {
      "text-color": "#fff",
      "text-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        ["match", ["get", "category"], ["OCEANIC"], 1, 0],
        5,
        ["match", ["get", "category"], ["OCEANIC"], 1, 0],
        9,
        ["match", ["get", "category"], ["OCEANIC"], 1, 1],
      ],
      "icon-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        ["match", ["get", "category"], ["OCEANIC"], 1, 0],
        5,
        ["match", ["get", "category"], ["OCEANIC"], 1, 0],
        9,
        ["match", ["get", "category"], ["OCEANIC"], 1, 1],
      ],
    },
  };

  return (
    <Source type="geojson" data={`${window.location.origin}/data/routes`}>
      <Layer {...routesLine} />
      <Layer {...routesSymbol} />
    </Source>
  );
}
