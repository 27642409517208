import React from "react";
import { adsbSelectedState } from "../../globalState";
import { Source, Layer } from "react-map-gl";
import { useRecoilValue } from "recoil";

export default function AdsbSitesSelected() {
  const adsbSelected = useRecoilValue(adsbSelectedState);
  const dataLayer = {
    id: "adsb_sites_selected",
    type: "symbol",
    layout: {
      "icon-image": "VHF_selected",
      "icon-size": 1,
      "icon-ignore-placement": true,
      "text-allow-overlap": true,
      "text-field": ["get", "name"],
      "text-optional": true,
      "text-offset": [0, 2],
      "text-size": 12,
      "text-font": ["Montserrat SemiBold"],
      "symbol-sort-key": 1,
      "symbol-spacing": 1,
    },
    filter: ["in", "designator", ...adsbSelected],
    paint: {
      "text-color": "#000000",
      "text-halo-width": 1,
      "text-halo-color": "#ffffff",
      "text-opacity": ["interpolate", ["linear"], ["zoom"], 6, 0, 7, 1],
    },
    minzoom: 5,
  };

  return (
    <Source type="geojson" data={`${window.location.origin}/data/adsb_sites`}>
      <Layer {...dataLayer} />
    </Source>
  );
}
