import React from "react";
import { Source, Layer } from "react-map-gl";
import { selectedAltitudeState } from "../../globalState";
import { useRecoilValue } from "recoil";

export default function RadioCoverageCombined() {
  const selectedAltitude = useRecoilValue(selectedAltitudeState);

  const dataLayer = {
    id: "radio_coverage",
    beforeId: "continent-label",
    type: "fill",
    layout: {},
    filter: ["==", "altitude", selectedAltitude],
    paint: {
      "fill-color": "#E91E63",
      "fill-opacity": 0.5,
    },
  };

  return (
    <Source
      type="geojson"
      data={`${window.location.origin}/data/coverage/radio_combined`}
    >
      <Layer {...dataLayer} />
    </Source>
  );
}
