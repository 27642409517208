import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Button,
  Box,
  useColorModeValue,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  layerState,
  layerStateHandler,
  vhfSelectedState,
  vhfPanelOpenState,
  adsbSelectedState,
  adsbPanelOpenState,
  navaidPanelOpenState,
  navaidTypeOpenState,
  navaidAccordionOpenState,
  navaidTypeSelectedState,
} from "../../globalState";
import { isMobileOnly, isIOS } from "react-device-detect";
import { GiRadarSweep } from "react-icons/gi";
import { VscRadioTower } from "react-icons/vsc";
import { MdGpsFixed } from "react-icons/md";
import { ReactComponent as AdsbSymbol } from "../symbols/adsb1.svg";
import { ReactComponent as NavaidSymbol } from "../symbols/vor-dme.svg";
import { ReactComponent as NzSymbol } from "../symbols/New_Zealand_Simplified.svg";

export default function Switches({ selectedNavaidTypes }) {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const setVhfSelected = useSetRecoilState(vhfSelectedState);
  const setAdsbSelected = useSetRecoilState(adsbSelectedState);
  const [vhfPanelOpen, setVhfPanelOpen] = useRecoilState(vhfPanelOpenState);
  const [adsbPanelOpen, setAdsbPanelOpen] = useRecoilState(adsbPanelOpenState);
  const [navaidPanelOpen, setNavaidPanelOpen] =
    useRecoilState(navaidPanelOpenState);
  const [navaidTypeOpen, setNavaidTypeOpen] =
    useRecoilState(navaidTypeOpenState);
  const [navaidTypeSelected, setNavaidTypeSelected] = useRecoilState(
    navaidTypeSelectedState
  );
  const setNavaidAccordionOpen = useSetRecoilState(navaidAccordionOpenState);
  const [expandedDropdowns, setExpandedDropdowns] = useState(-1);

  const navBg = useColorModeValue("white", "gray.700");
  const switchBg = useColorModeValue(
    "rgba(20, 50, 150, 0.04)",
    "rgba(255,255,255,0.04)"
  );
  const switchBgActive = useColorModeValue("gray.200", "#1A202A");
  const switchBgHover = useColorModeValue(
    "rgba(20, 50, 150, 0.07)",
    "rgba(255,255,255,0.07)"
  );
  const switchMenuBg = useColorModeValue("#f2f5fa", "#222937");
  const switchMenuBgShadow = useColorModeValue(
    "inset 0px 3px 10px -3px rgba(10, 20, 30, 0.35)",
    "inset 0px 3px 10px -3px rgba(10, 20, 30, 0.9)"
  );
  const buttonBgHover = useColorModeValue(
    "rgba(20, 50, 150, 0.07)",
    "rgba(255,255,255,0.07)"
  );
  const buttonColor = useColorModeValue("gray.900", "white");
  const buttonBorderColor = useColorModeValue("gray.900", "white");
  const buttonColorHover = useColorModeValue("gray.800", "white");
  const buttonColorOpacity = useColorModeValue("0.9", "0.5");
  const dividerColor = useColorModeValue(
    "rgba(20, 50, 150, 0.15)",
    "rgba(255,255,255,0.1)"
  );
  // close popout Site sub-menus if accordion closes
  useEffect(() => {
    if (expandedDropdowns !== 0) {
      setAdsbPanelOpen(false);
    }
    if (expandedDropdowns !== 1) {
      setVhfPanelOpen(false);
    }
    if (expandedDropdowns !== 2) {
      setNavaidPanelOpen(false);
      setNavaidTypeOpen(false);
      setNavaidAccordionOpen(false);
    }
  }, [
    expandedDropdowns,
    setAdsbPanelOpen,
    setVhfPanelOpen,
    setNavaidTypeOpen,
    setNavaidPanelOpen,
    setNavaidAccordionOpen,
  ]);

  useEffect(() => {
    // if individual sites are selected, then toggle off 'Show All'
    if (layers.adsb && layers.adsb_by_site) {
      layerHandler("adsb");
    }
    if (layers.vhf_combined && layers.vhf) {
      layerHandler("vhf_combined");
    }
    if (layers.navaid_combined && layers.navaid_coverage) {
      layerHandler("navaid_combined");
    }
  }, [layers, layerHandler, setNavaidTypeSelected]);

  const isIphone = isMobileOnly && isIOS;

  useEffect(() => {
    // console.log("layers", layers);
  }, [layers]);

  return (
    <>
      <Flex
        width={{ base: "100%", md: "55px" }}
        height={{ base: isIphone ? "75px" : "60px", md: "100%" }}
        zIndex="1"
        flexDirection={{ base: "row", md: "column" }}
        alignItems="center"
        justifyContent={{ base: "center", md: "flex-start" }}
        px={{
          base: "10vw",
          sm: expandedDropdowns > -1 ? "0vw" : "10vw",
          md: "0px",
        }}
        transition="all ease 200ms"
      >
        {/* <Divider orientation="vertical" borderColor={dividerColor} /> */}

        <Flex // MSSR
          height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
          width={{
            base: expandedDropdowns > -1 ? "0px" : "20vw",
            sm:
              expandedDropdowns > -1 ? "calc((100vw - 150px) * 0.25)" : "20vw",
            md: "55px",
          }}
          minWidth={{
            base: expandedDropdowns > -1 ? "0px" : "20vw",
            sm:
              expandedDropdowns > -1 ? "calc((100vw - 150px) * 0.25)" : "20vw",
            md: "55px",
          }}
          overflow={{ base: "hidden", sm: "unset" }}
          transition="all ease 200ms"
          p="0px"
          pb={{ base: isIphone ? "15px" : "5px", md: "0px" }}
          alignItems="center"
          justifyContent="center"
          bg={layers.ssr ? switchBg : navBg}
          color={layers.ssr ? "ssr.500" : buttonColor}
          flexDirection="column"
          gap="1"
          opacity={
            expandedDropdowns > -1
              ? "0.2"
              : layers.ssr
              ? "1"
              : buttonColorOpacity
          }
          fontSize="10"
          fontWeight="400"
          fontFamily="Montserrat, sans-serif"
          onClick={() => layerHandler("ssr")}
          cursor="pointer"
          _hover={{
            bg: buttonBgHover,
            opacity: "1",
            color: layers.ssr ? "ssr.500" : buttonColorHover,
          }}
          _expanded={{
            bg: switchBg,
            opacity: "1",
            color: layers.ssr ? "ssr.500" : buttonColorHover,
            fontWeight: "600",
          }}
          _active={{ bg: switchBg, color: "ssr.500", fontWeight: "600" }}
        >
          <Flex
            pt="8px"
            height="30px"
            minHeight="30px"
            alignItems="center"
            justifyContent="center"
          >
            <GiRadarSweep fontSize="1.6rem" />
          </Flex>
          <Text>MSSR</Text>
        </Flex>

        {/* <Divider orientation="vertical" borderColor={dividerColor} /> */}

        <Flex // ADSB (0)
          height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
          width={{
            base:
              expandedDropdowns > -1 && expandedDropdowns !== 0
                ? "0px"
                : expandedDropdowns === 0
                ? "60vw"
                : "25%",
            xs:
              expandedDropdowns > -1 && expandedDropdowns !== 0
                ? "0px"
                : expandedDropdowns === 0
                ? "60vw"
                : "25%",
            sm: expandedDropdowns === 0 ? "calc(25vw + 150px)" : "25%",
            md: "55px",
          }}
          transition="all ease 200ms"
          opacity={
            expandedDropdowns > -1 && expandedDropdowns !== 0 ? "0.2" : "1"
          }
          overflow={{ base: "hidden", sm: "unset" }}
        >
          <Button
            borderRadius="0px"
            transition="all ease 200ms"
            height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
            width="100%"
            minWidth={{
              base:
                expandedDropdowns > -1 && expandedDropdowns !== 0
                  ? "0px"
                  : expandedDropdowns > -1
                  ? "calc(60vw * 0.25)"
                  : "20vw",
              xs:
                expandedDropdowns > -1 && expandedDropdowns !== 0
                  ? "0px"
                  : expandedDropdowns > -1
                  ? "calc(60vw * 0.25)"
                  : "20vw",
              sm:
                expandedDropdowns > -1
                  ? "calc((100vw - 150px) * 0.25)"
                  : "20vw",
              md: "55px",
            }}
            p="0px"
            alignItems="center"
            justifyContent="center"
            color={
              layers.adsb || layers.adsb_by_site ? "adsb.500" : buttonColor
            }
            opacity={
              layers.adsb || layers.adsb_by_site ? "1" : buttonColorOpacity
            }
            bg={
              expandedDropdowns === 0
                ? switchBg
                : layers.adsb || layers.adsb_by_site
                ? switchBg
                : navBg
            }
            fontSize="10"
            fontWeight="400"
            fontFamily="Montserrat, sans-serif"
            onClick={() => {
              if (expandedDropdowns === 0) {
                setExpandedDropdowns(-1);
                setAdsbPanelOpen(false);
                setVhfPanelOpen(false);
                setNavaidPanelOpen(false);
                setNavaidAccordionOpen(false);
              } else {
                setExpandedDropdowns(0);
              }
            }}
            _hover={{
              bg: expandedDropdowns === 0 ? buttonBgHover : navBg,
              opacity: "1",
              color:
                layers.adsb || layers.adsb_by_site
                  ? "adsb.500"
                  : buttonColorHover,
            }}
            _expanded={{
              bg: expandedDropdowns === 0 ? switchBg : navBg,
              opacity: "1",
              color:
                layers.adsb || layers.adsb_by_site
                  ? "adsb.500"
                  : buttonColorHover,
              fontWeight: "600",
            }}
            pb={{ base: isIphone ? "15px" : "5px", md: "0px" }}
          >
            <Flex flexDirection="column" alignItems="center" gap="1">
              <Flex
                height="30px"
                minHeight="30px"
                alignItems="center"
                justifyContent="center"
              >
                <AdsbSymbol height="25" width="auto" />
              </Flex>
              <Text>ADSB</Text>
            </Flex>
          </Button>
          <Flex
            bg={switchMenuBg}
            color={buttonColor}
            fontSize="10"
            fontWeight="400"
            fontFamily="Montserrat, sans-serif"
            width={
              expandedDropdowns === 0 ? { base: "40vw", sm: "150px" } : "0px"
            }
            minWidth={
              expandedDropdowns === 0 ? { base: "40vw", sm: "150px" } : "0px"
            }
            maxWidth={
              expandedDropdowns === 0 ? { base: "40vw", sm: "150px" } : "0px"
            }
            height={{ base: isIphone ? "75px" : "60px", md: "100%" }}
            transition="all ease 200ms"
            direction="bottom"
            alignItems="center"
            justifyContent="space-evenly"
            boxShadow={switchMenuBgShadow}
            overflow="hidden"
          >
            <Flex
              cursor="pointer"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap="5px"
              minHeight={{ base: isIphone ? "75px" : "60px", md: "80px" }}
              width="100%"
              opacity={expandedDropdowns === 0 ? "0.8" : "0"}
              transition="all ease 200ms"
              bg={layers.adsb ? switchBg : undefined}
              color={layers.adsb ? "adsb.500" : undefined}
              onClick={() => {
                layerHandler("adsb");
                if (layers.adsb_by_site) {
                  layerHandler("adsb_by_site");
                }
                setAdsbPanelOpen(false);
                setAdsbSelected([]);
              }}
              _active={{
                bg: switchBgActive,
                color: layers.adsb ? "adsb.500" : buttonColorHover,

                opacity: "1",
              }}
              _hover={{
                bg: switchBgHover,
                color: layers.adsb ? "adsb.500" : buttonColorHover,

                opacity: "1",
              }}
              pt="8px"
              pb={{ base: isIphone ? "20px" : "10px", md: "0px" }}
            >
              <Icon
                ml="10px"
                as={NzSymbol}
                height={expandedDropdowns === 0 ? "28px" : "0px"}
                width="auto"
              />
              <Text
                width="100%"
                textAlign="center"
                lineHeight="1.2"
                fontSize={expandedDropdowns === 0 ? "0.55rem" : "0rem"}
                whiteSpace="pre"
              >
                SHOW ALL
              </Text>
            </Flex>
            <Divider orientation="vertical" borderColor={dividerColor} />

            <Flex
              cursor="pointer"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
              minHeight={{ base: isIphone ? "75px" : "60px", md: "80px" }}
              width="100%"
              opacity={
                adsbPanelOpen ? "1" : expandedDropdowns === 0 ? "0.8" : "0"
              }
              transition="all ease 200ms, padding ease-out 600ms"
              bg={adsbPanelOpen ? switchBgActive : undefined}
              color={layers.adsb_by_site ? "adsb.500" : buttonColor}
              onClick={() => {
                setAdsbPanelOpen((prevState) => !prevState);
              }}
              _active={{
                bg: switchBgActive,
                color: layers.adsb_by_site ? "adsb.500" : buttonColor,
                opacity: "1",
              }}
              _hover={{
                bg: adsbPanelOpen ? switchBgActive : switchBgHover,
                color: layers.adsb_by_site ? "adsb.500" : buttonColor,
                opacity: "1",
              }}
              pt="6px"
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="7px"
                height="100%"
                width="100%"
                pt="5px"
                pb={{ base: isIphone ? "25px" : "15px", md: "0px" }}
              >
                <Flex
                  pt="5px"
                  height="28px"
                  minHeight="28px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon
                    as={MdGpsFixed}
                    fontSize={expandedDropdowns === 0 ? "1.4rem" : "0rem"}
                    width="auto"
                  />
                </Flex>
                <Text
                  width="100%"
                  textAlign="center"
                  lineHeight="1.2"
                  fontSize={expandedDropdowns === 0 ? "0.55rem" : "0rem"}
                  whiteSpace="pre"
                >
                  BY SITE
                </Text>
              </Flex>
              <Box
                opacity={adsbPanelOpen ? "1" : "0"}
                transition="all ease 600ms, background ease 300ms"
                width="100%"
                height="3px"
                bg={layers.adsb_by_site ? "adsb.500" : buttonBorderColor}
                marginTop={adsbPanelOpen ? "-3px" : "0px"}
                marginBottom={adsbPanelOpen ? "0px" : "-3px"}
                zIndex="1"
              ></Box>
            </Flex>
          </Flex>
        </Flex>

        {/* <Divider orientation="vertical" borderColor={dividerColor} /> */}

        <Flex // VHF (1)
          height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
          width={{
            base:
              expandedDropdowns > -1 && expandedDropdowns !== 1
                ? "0px"
                : expandedDropdowns === 1
                ? "60vw"
                : "25%",
            xs:
              expandedDropdowns > -1 && expandedDropdowns !== 1
                ? "0px"
                : expandedDropdowns === 1
                ? "60vw"
                : "25%",
            sm: expandedDropdowns === 1 ? "calc(25vw + 150px)" : "25%",
            md: "55px",
          }}
          transition="all ease 200ms"
          opacity={
            expandedDropdowns > -1 && expandedDropdowns !== 1 ? "0.2" : "1"
          }
          overflow={{ base: "hidden", sm: "unset" }}
        >
          <Button
            borderRadius="0px"
            transition="all ease 200ms"
            height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
            width="100%"
            minWidth={{
              base:
                expandedDropdowns > -1 && expandedDropdowns !== 1
                  ? "0px"
                  : expandedDropdowns > -1
                  ? "calc(60vw * 0.25)"
                  : "20vw",
              xs:
                expandedDropdowns > -1 && expandedDropdowns !== 1
                  ? "0px"
                  : expandedDropdowns > -1
                  ? "calc(60vw * 0.25)"
                  : "20vw",
              sm:
                expandedDropdowns > -1
                  ? "calc((100vw - 150px) * 0.25)"
                  : "20vw",
              md: "55px",
            }}
            p="0px"
            alignItems="center"
            justifyContent="center"
            color={layers.vhf || layers.vhf_combined ? "vhf.500" : buttonColor}
            opacity={
              layers.vhf || layers.vhf_combined ? "1" : buttonColorOpacity
            }
            bg={
              expandedDropdowns === 1
                ? switchBg
                : layers.vhf || layers.vhf_combined
                ? switchBg
                : navBg
            }
            fontSize="10"
            fontWeight="400"
            fontFamily="Montserrat, sans-serif"
            onClick={() => {
              if (expandedDropdowns === 1) {
                setExpandedDropdowns(-1);
                setAdsbPanelOpen(false);
                setVhfPanelOpen(false);
                setNavaidPanelOpen(false);
                setNavaidAccordionOpen(false);
              } else {
                setExpandedDropdowns(1);
              }
            }}
            _hover={{
              bg: expandedDropdowns === 1 ? buttonBgHover : navBg,
              opacity: "1",
              color:
                layers.vhf || layers.vhf_combined
                  ? "vhf.500"
                  : buttonColorHover,
            }}
            _expanded={{
              bg: expandedDropdowns === 2 ? switchBg : navBg,
              opacity: "1",
              color:
                layers.vhf || layers.vhf_combined
                  ? "vhf.500"
                  : buttonColorHover,
              fontWeight: "600",
            }}
            pb={{ base: isIphone ? "15px" : "5px", md: "0px" }}
          >
            <Flex flexDirection="column" alignItems="center" gap="1">
              <Flex
                pt="5px"
                height="30px"
                minHeight="30px"
                alignItems="center"
                justifyContent="center"
              >
                <VscRadioTower fontSize="1.7rem" />
              </Flex>
              <Text>VHF</Text>
            </Flex>
          </Button>
          <Flex
            bg={switchMenuBg}
            color={buttonColor}
            fontSize="10"
            fontWeight="400"
            fontFamily="Montserrat, sans-serif"
            width={
              expandedDropdowns === 1 ? { base: "40vw", sm: "150px" } : "0px"
            }
            minWidth={
              expandedDropdowns === 1 ? { base: "40vw", sm: "150px" } : "0px"
            }
            maxWidth={
              expandedDropdowns === 1 ? { base: "40vw", sm: "150px" } : "0px"
            }
            height={{ base: isIphone ? "75px" : "60px", md: "100%" }}
            transition="all ease 200ms"
            direction="bottom"
            alignItems="center"
            justifyContent="space-evenly"
            boxShadow={switchMenuBgShadow}
            overflow="hidden"
          >
            <Flex
              cursor="pointer"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap="5px"
              minHeight={{ base: isIphone ? "75px" : "60px", md: "80px" }}
              width="100%"
              opacity={expandedDropdowns === 1 ? "0.8" : "0"}
              transition="all ease 200ms"
              bg={layers.vhf_combined ? switchBg : undefined}
              color={layers.vhf_combined ? "vhf.500" : undefined}
              onClick={() => {
                if (layers.vhf) {
                  layerHandler("vhf");
                }
                layerHandler("vhf_combined");
                setVhfSelected([]);
                setVhfPanelOpen(false);
              }}
              _active={{
                bg: switchBgActive,
                color: layers.vhf_combined ? "vhf.500" : buttonColor,

                opacity: "1",
              }}
              _hover={{
                bg: switchBgHover,
                color: layers.vhf_combined ? "vhf.500" : buttonColor,

                opacity: "1",
              }}
              pt="8px"
              pb={{ base: isIphone ? "20px" : "10px", md: "0px" }}
            >
              <Icon
                ml="10px"
                as={NzSymbol}
                height={expandedDropdowns === 1 ? "28px" : "0px"}
                width="auto"
              />
              <Text
                width="100%"
                textAlign="center"
                lineHeight="1.2"
                fontSize={expandedDropdowns === 1 ? "0.55rem" : "0rem"}
                whiteSpace="pre"
              >
                SHOW ALL
              </Text>
            </Flex>
            <Divider orientation="vertical" borderColor={dividerColor} />

            <Flex
              cursor="pointer"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
              minHeight={{ base: isIphone ? "75px" : "60px", md: "80px" }}
              width="100%"
              opacity={
                vhfPanelOpen ? "1" : expandedDropdowns === 1 ? "0.8" : "0"
              }
              transition="all ease 200ms, padding ease-out 600ms"
              bg={vhfPanelOpen ? switchBgActive : undefined}
              color={layers.vhf ? "vhf.500" : buttonColor}
              onClick={() => {
                setVhfPanelOpen((prevState) => !prevState);
              }}
              _active={{
                bg: switchBgActive,
                color: layers.vhf ? "vhf.500" : buttonColor,
                opacity: "1",
              }}
              _hover={{
                bg: vhfPanelOpen ? switchBgActive : switchBgHover,
                color: layers.vhf ? "vhf.500" : buttonColor,
                opacity: "1",
              }}
              pt="6px"
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="7px"
                height="100%"
                width="100%"
                pt="5px"
                pb={{ base: isIphone ? "25px" : "15px", md: "0px" }}
              >
                <Flex
                  pt="5px"
                  height="28px"
                  minHeight="28px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon
                    as={MdGpsFixed}
                    fontSize={expandedDropdowns === 1 ? "1.4rem" : "0rem"}
                    width="auto"
                  />
                </Flex>
                <Text
                  width="100%"
                  textAlign="center"
                  lineHeight="1.2"
                  fontSize={expandedDropdowns === 1 ? "0.55rem" : "0rem"}
                  whiteSpace="pre"
                >
                  BY SITE
                </Text>
              </Flex>
              <Box
                opacity={vhfPanelOpen ? "1" : "0"}
                transition="all ease 600ms, background ease 300ms"
                width="100%"
                height="3px"
                bg={layers.vhf ? "vhf.500" : buttonBorderColor}
                marginTop={vhfPanelOpen ? "-3px" : "0px"}
                marginBottom={vhfPanelOpen ? "0px" : "-3px"}
                zIndex="1"
              ></Box>
            </Flex>
          </Flex>
        </Flex>

        {/* <Divider orientation="vertical" borderColor={dividerColor} /> */}

        <Flex // NAVAID (2)
          height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
          width={{
            base:
              expandedDropdowns > -1 && expandedDropdowns !== 2
                ? "0px"
                : expandedDropdowns === 2
                ? "60vw"
                : "25%",
            xs:
              expandedDropdowns > -1 && expandedDropdowns !== 2
                ? "0px"
                : expandedDropdowns === 2
                ? "60vw"
                : "25%",
            sm: expandedDropdowns === 2 ? "calc(25vw + 150px)" : "25%",
            md: "55px",
          }}
          transition="all ease 200ms"
          opacity={
            expandedDropdowns > -1 && expandedDropdowns !== 2 ? "0.2" : "1"
          }
          overflow={{ base: "hidden", sm: "unset" }}
        >
          <Button
            borderRadius="0px"
            transition="all ease 200ms"
            height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
            width="100%"
            minWidth={{
              base:
                expandedDropdowns > -1 && expandedDropdowns !== 2
                  ? "0px"
                  : expandedDropdowns > -1
                  ? "calc(60vw * 0.25)"
                  : "20vw",
              xs:
                expandedDropdowns > -1 && expandedDropdowns !== 2
                  ? "0px"
                  : expandedDropdowns > -1
                  ? "calc(60vw * 0.25)"
                  : "20vw",
              sm:
                expandedDropdowns > -1
                  ? "calc((100vw - 150px) * 0.25)"
                  : "20vw",
              md: "55px",
            }}
            p="0px"
            alignItems="center"
            justifyContent="center"
            color={
              layers.navaid_combined && navaidTypeSelected !== ""
                ? navaidTypeSelected === "VOR"
                  ? "vor.600"
                  : "dme.600"
                : layers.navaid_coverage
                ? selectedNavaidTypes
                : buttonColor
            }
            opacity={
              (layers.navaid_combined && navaidTypeSelected !== "") ||
              layers.navaid_coverage
                ? "1"
                : buttonColorOpacity
            }
            bg={
              expandedDropdowns === 2
                ? switchBg
                : (layers.navaid_combined && navaidTypeSelected !== "") ||
                  layers.navaid_coverage
                ? switchBg
                : navBg
            }
            fontSize="10"
            fontWeight="400"
            fontFamily="Montserrat, sans-serif"
            onClick={() => {
              if (expandedDropdowns === 2) {
                setExpandedDropdowns(-1);
                setAdsbPanelOpen(false);
                setVhfPanelOpen(false);
                setNavaidPanelOpen(false);
                setNavaidAccordionOpen(false);
              } else {
                setExpandedDropdowns(2);
              }
            }}
            _hover={{
              bg: expandedDropdowns === 2 ? buttonBgHover : navBg,
              opacity: "1",
              color:
                layers.navaid_combined && navaidTypeSelected !== ""
                  ? navaidTypeSelected === "VOR"
                    ? "vor.600"
                    : "dme.600"
                  : layers.navaid_coverage
                  ? selectedNavaidTypes
                  : buttonColorHover,
            }}
            _expanded={{
              bg: expandedDropdowns === 2 ? switchBg : navBg,
              opacity: "1",
              color:
                layers.navaid_combined && navaidTypeSelected !== ""
                  ? navaidTypeSelected === "VOR"
                    ? "vor.600"
                    : "dme.600"
                  : layers.navaid_coverage
                  ? selectedNavaidTypes
                  : buttonColorHover,
              fontWeight: "600",
            }}
            pb={{ base: isIphone ? "15px" : "5px", md: "0px" }}
          >
            <Flex flexDirection="column" alignItems="center" gap="1">
              <Flex
                pt="5px"
                height="30px"
                minHeight="30px"
                alignItems="center"
                justifyContent="center"
              >
                <NavaidSymbol height="28px" width="auto" />
              </Flex>
              <Text>NAVAID</Text>
            </Flex>
          </Button>
          <Flex
            bg={switchMenuBg}
            color={buttonColor}
            fontSize="10"
            fontWeight="400"
            fontFamily="Montserrat, sans-serif"
            width={
              expandedDropdowns === 2 ? { base: "40vw", sm: "150px" } : "0px"
            }
            minWidth={
              expandedDropdowns === 2 ? { base: "40vw", sm: "150px" } : "0px"
            }
            maxWidth={
              expandedDropdowns === 2 ? { base: "40vw", sm: "150px" } : "0px"
            }
            height={{ base: isIphone ? "75px" : "60px", md: "100%" }}
            transition="all ease 200ms"
            direction="bottom"
            alignItems="center"
            justifyContent="space-evenly"
            boxShadow={switchMenuBgShadow}
            overflow="hidden"
          >
            <Flex
              cursor="pointer"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap="5px"
              height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
              minHeight={{ base: isIphone ? "75px" : "60px", md: "80px" }}
              width="100%"
              opacity={
                navaidTypeOpen ? "1" : expandedDropdowns === 2 ? "0.8" : "0"
              }
              transition="all ease 200ms, padding ease-out 600ms"
              bg={navaidTypeOpen ? switchBgActive : undefined}
              color={
                layers.navaid_combined && navaidTypeSelected !== ""
                  ? navaidTypeSelected === "VOR"
                    ? "vor.600"
                    : "dme.600"
                  : undefined
              }
              onClick={() => {
                setNavaidTypeOpen((prevState) => !prevState);
                if (navaidPanelOpen) {
                  setNavaidPanelOpen(false);
                }
              }}
              _active={{
                bg: switchBgActive,
                color:
                  layers.navaid_combined && navaidTypeSelected !== ""
                    ? navaidTypeSelected === "VOR"
                      ? "vor.600"
                      : "dme.600"
                    : buttonColor,

                opacity: "1",
              }}
              _hover={{
                bg: navaidTypeOpen ? switchBgActive : switchBgHover,
                color:
                  layers.navaid_combined && navaidTypeSelected !== ""
                    ? navaidTypeSelected === "VOR"
                      ? "vor.600"
                      : "dme.600"
                    : buttonColor,

                opacity: "1",
              }}
              pt="6px"
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="7px"
                height="100%"
                width="100%"
                pt="10px"
                pb={{ base: isIphone ? "25px" : "15px", md: "0px" }}
              >
                <Icon
                  ml="10px"
                  as={NzSymbol}
                  height={expandedDropdowns === 2 ? "28px" : "0px"}
                  width="auto"
                />
                <Text
                  width="100%"
                  textAlign="center"
                  lineHeight="1.2"
                  fontSize={expandedDropdowns === 2 ? "0.55rem" : "0rem"}
                  whiteSpace="pre"
                >
                  SHOW ALL
                </Text>
              </Flex>
              <Box
                opacity={navaidTypeOpen ? "1" : "0"}
                transition="all ease 600ms, background ease 300ms"
                width="100%"
                height="3px"
                minHeight="3px"
                bg={
                  layers.navaid_combined && navaidTypeSelected !== ""
                    ? navaidTypeSelected === "VOR"
                      ? "vor.600"
                      : "dme.600"
                    : buttonBorderColor
                }
                marginTop={navaidTypeOpen ? "-5px" : "0px"}
                marginBottom={navaidTypeOpen ? "3px" : "-3px"}
                zIndex="1"
              ></Box>
            </Flex>
            <Divider orientation="vertical" borderColor={dividerColor} />

            <Flex
              cursor="pointer"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height={{ base: isIphone ? "75px" : "60px", md: "80px" }}
              minHeight={{ base: isIphone ? "75px" : "60px", md: "80px" }}
              width="100%"
              opacity={
                navaidPanelOpen ? "1" : expandedDropdowns === 2 ? "0.8" : "0"
              }
              transition="all ease 200ms, padding ease-out 600ms"
              bg={navaidPanelOpen ? switchBgActive : undefined}
              color={layers.navaid_coverage ? selectedNavaidTypes : buttonColor}
              onClick={() => {
                setNavaidPanelOpen((prevState) => !prevState);
                setNavaidTypeOpen(false);
              }}
              _active={{
                bg: switchBgActive,
                color: layers.navaid_coverage
                  ? selectedNavaidTypes
                  : buttonColor,
                opacity: "1",
              }}
              _hover={{
                bg: navaidPanelOpen ? switchBgActive : switchBgHover,
                color: layers.navaid_coverage
                  ? selectedNavaidTypes
                  : buttonColor,
                opacity: "1",
              }}
              pt="6px"
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="7px"
                height="100%"
                width="100%"
                pt="5px"
                pb={{ base: isIphone ? "25px" : "15px", md: "0px" }}
              >
                <Flex
                  pt="5px"
                  height="28px"
                  minHeight="28px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon
                    as={MdGpsFixed}
                    fontSize={expandedDropdowns === 2 ? "1.4rem" : "0rem"}
                    width="auto"
                  />
                </Flex>
                <Text
                  width="100%"
                  textAlign="center"
                  lineHeight="1.2"
                  fontSize={expandedDropdowns === 2 ? "0.55rem" : "0rem"}
                  whiteSpace="pre"
                >
                  BY SITE
                </Text>
              </Flex>
              <Box
                opacity={navaidPanelOpen ? "1" : "0"}
                transition="all ease 600ms, background ease 300ms"
                width="100%"
                height="3px"
                bg={
                  layers.navaid_coverage
                    ? selectedNavaidTypes
                    : buttonBorderColor
                }
                marginTop={navaidPanelOpen ? "-3px" : "0px"}
                marginBottom={navaidPanelOpen ? "0px" : "-3px"}
                zIndex="1"
              ></Box>
            </Flex>
          </Flex>
        </Flex>
        {/* <Divider orientation="vertical" borderColor={dividerColor} /> */}
      </Flex>
    </>
  );
}
