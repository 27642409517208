import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  Center,
  Flex,
  SlideFade,
  VStack,
  Tooltip,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import standardImage from "../assets/standard.png";
import vncImage from "../assets/vnc.png";
import linzImage from "../assets/linztopo.png";
import satelliteImage from "../assets/satellite.png";
import { isDesktop } from "react-device-detect";
import { basemapState, layerState, layerStateHandler } from "../../globalState";

export default function ImagerySwitcher(props) {
  const { show, setShow } = props;
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const [basemap, setBasemap] = useRecoilState(basemapState);

  useEffect(() => {
    setShow(false);
  }, [basemap, setShow]);

  useEffect(() => {
    if (basemap === "standard") {
      if (layers.satellite) {
        layerHandler("satellite");
      }
      if (layers.vnc) {
        layerHandler("vnc");
      }
      if (layers.linz) {
        layerHandler("linz");
      }
    }
    if (basemap === "satellite" && !layers.satellite) {
      if (layers.vnc) {
        layerHandler("vnc");
      }
      if (layers.linz) {
        layerHandler("linz");
      }
      layerHandler("satellite");
    }
    if (basemap === "vnc" && !layers.vnc) {
      if (layers.satellite) {
        layerHandler("satellite");
      }
      if (layers.linz) {
        layerHandler("linz");
      }
      layerHandler("vnc");
    }
    if (basemap === "linz" && !layers.linz) {
      if (layers.satellite) {
        layerHandler("satellite");
      }
      if (layers.vnc) {
        layerHandler("vnc");
      }
      layerHandler("linz");
    }
  }, [basemap, layers, layerHandler]);

  const buttonColor = useColorModeValue("#FFFFFF", "#324051");

  // useEffect(() => {
  //   setLayerSelection((prevState) => {
  //     return {
  //       ...prevState,
  //       constant: {
  //         ...prevState.constant,
  //         satellite: basemap === "satellite",
  //         vnc: basemap === "vnc",
  //         linztopo: basemap === "linztopo",
  //       },
  //     };
  //   });
  // }, [basemap, setLayerSelection]);

  return (
    <>
      <Tooltip
        display={isDesktop ? "flex" : "none"}
        isDisabled={isDesktop ? false : true}
        label="Change Base Map"
        visibility={!show ? "visible" : "hidden"}
        hasArrow
        borderRadius="7px"
        height="26px"
        fontSize="0.7rem"
        alignItems="center"
        placement="left"
        m="5px"
        color="white"
        bg="gray.600"
      >
        <Flex flexDirection="column">
          <Center // STANDARD MAP and OPEN BUTTON
            zIndex="1"
            w={{ base: "32px", md: "30px" }}
            h={{ base: "32px", md: "30px" }}
            bgImage={`url(${
              !show && basemap === "standard"
                ? standardImage
                : !show && basemap === "vnc"
                ? vncImage
                : !show && basemap === "linz"
                ? linzImage
                : !show && basemap === "satellite"
                ? satelliteImage
                : standardImage
            })`}
            bgSize="contain"
            bgPosition="center"
            bgRepeat="no-repeat"
            border={
              show && basemap === "standard"
                ? "2px solid"
                : show && basemap !== "standard"
                ? "0px solid"
                : "2px solid"
            }
            borderColor={buttonColor}
            borderRadius="10px"
            _hover={{
              backgroundColor: "white.100",
              cursor: "pointer",
              boxShadow: "inset 0px 0px 20px -5px rgba(0,0,0,0.2)",
            }}
            onClick={() => {
              if (!show) setShow((prevState) => !prevState);
              if (show && basemap === "standard")
                setShow((prevState) => !prevState);
              if (show) setBasemap("standard");
            }}
          />
          <Text
            position="absolute"
            opacity={show ? "1" : "0"}
            transition="all ease 250ms"
            top="5px"
            right="45px"
            pointerEvents="none"
            fontSize="12px"
            letterSpacing="0.2px"
            textAlign="right"
            whiteSpace="nowrap"
            color="white"
            textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
            fontWeight="500"
          >
            Standard
          </Text>
        </Flex>
      </Tooltip>
      <SlideFade
        unmountOnExit
        offsetY="-20px"
        animateOpacity
        marginTop="0"
        style={{
          marginTop: "0px !important",
          overflow: "initial",
        }}
        zIndex={show ? "1" : "0"}
        in={show}
      >
        <Flex
          zIndex={show ? "1" : "0"}
          w="100%"
          marginTop="3"
          marginBottom="3"
          direction={"column"}
          // display={!sliderVisible && 'none'}
        >
          <VStack
            spacing="3"
            alignItems="flex-end"
            // pb={!sliderVisible ? '15px' : '0'}
          >
            <Flex // SATELLITE BASE MAP
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Text
                opacity={show ? "1" : "0"}
                position="relative"
                transition="all ease 500ms"
                right="15px"
                top="5px"
                pointerEvents="none"
                fontSize="12px"
                letterSpacing="0.2px"
                textAlign="right"
                whiteSpace="nowrap"
                color="rgb(255, 255, 255)"
                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                fontWeight="500"
              >
                Satellite
              </Text>
              <Center
                w={{ base: "32px", md: "30px" }}
                h={{ base: "32px", md: "30px" }}
                bgImage={`url(${satelliteImage})`}
                bgSize="contain"
                bgPosition="center"
                bgRepeat="no-repeat"
                border={basemap === "satellite" ? "2px solid" : "0px solid"}
                borderColor={buttonColor}
                borderRadius="10px"
                _hover={{
                  backgroundColor: "white.100",
                  cursor: "pointer",
                  boxShadow: "inset 0px 0px 20px -5px rgba(0,0,0,0.2)",
                }}
                onClick={() => setBasemap("satellite")}
              />
            </Flex>

            <Flex // VNC BASE MAP
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Text
                opacity={show ? "1" : "0"}
                position="relative"
                transition="all ease 500ms"
                right="15px"
                top="5px"
                pointerEvents="none"
                fontSize="12px"
                letterSpacing="0.2px"
                textAlign="right"
                whiteSpace="nowrap"
                color="rgb(255, 255, 255)"
                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                fontWeight="500"
              >
                VNC
              </Text>
              <Center
                w={{ base: "32px", md: "30px" }}
                h={{ base: "32px", md: "30px" }}
                bgImage={`url(${vncImage})`}
                bgSize="contain"
                bgPosition="center"
                bgRepeat="no-repeat"
                border={basemap === "vnc" ? "2px solid" : "0px solid"}
                borderColor={buttonColor}
                borderRadius="10px"
                _hover={{
                  backgroundColor: "white.100",
                  cursor: "pointer",
                  boxShadow: "inset 0px 0px 20px -5px rgba(0,0,0,0.2)",
                }}
                onClick={() => setBasemap("vnc")}
              />
            </Flex>
            <Flex // LINZ BASE MAP
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Text
                opacity={show ? "1" : "0"}
                position="relative"
                transition="all ease 500ms"
                right="15px"
                top="5px"
                pointerEvents="none"
                fontSize="12px"
                letterSpacing="0.2px"
                textAlign="right"
                whiteSpace="nowrap"
                color="rgb(255, 255, 255)"
                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                fontWeight="500"
              >
                LINZ Topo
              </Text>
              <Center
                w={{ base: "32px", md: "30px" }}
                h={{ base: "32px", md: "30px" }}
                bgImage={`url(${linzImage})`}
                bgSize="contain"
                bgPosition="center"
                bgRepeat="no-repeat"
                border={basemap === "vnc" ? "2px solid" : "0px solid"}
                borderColor={buttonColor}
                borderRadius="10px"
                _hover={{
                  backgroundColor: "white.100",
                  cursor: "pointer",
                  boxShadow: "inset 0px 0px 20px -5px rgba(0,0,0,0.2)",
                }}
                onClick={() => setBasemap("linz")}
              />
            </Flex>
          </VStack>
        </Flex>
      </SlideFade>
    </>
  );
}
