import React from "react";
import { useRecoilValue } from "recoil";
import { Map as MapInstance } from "react-map-gl";
import Layers from "./layers";
import Symbols from "./symbols";
import "mapbox-gl/dist/mapbox-gl.css";
import Controls from "./controls";
import Actions from "./actions";
import { symbolsLoadedState } from "../globalState";

import { Flex, useBreakpointValue } from "@chakra-ui/react";
import mapboxgl from "mapbox-gl";

/* eslint-disable import/no-webpack-loader-syntax */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
/* eslint-disable import/no-webpack-loader-syntax */

export default function Map() {
  const symbolsLoaded = useRecoilValue(symbolsLoadedState);
  const mapSize = useBreakpointValue({
    base: "100%",
    md: "calc(100% - 55px)",
  });
  const mapInitialView = window.innerWidth < 768 ? 172.5 : 174.7787;

  return (
    <MapInstance
      // onLoad={(e) => {
      //   e.target.showCollisionBoxes = true;
      //   e.target.showTileBoundaries = true;
      // }}
      onClick={(e) => {
        console.log(e.target.getStyle().layers);
      }}
      // onZoom={(e) => {
      //   console.log(e.target.getZoom());
      // }}
      minZoom={3}
      maxZoom={13}
      maxBounds={[
        [150, -55],
        [-160, -25],
      ]}
      reuseMaps
      preserveDrawingBuffer
      initialViewState={{
        longitude: mapInitialView,
        latitude: -41.2924,
        zoom: 5,
      }}
      // mapStyle={"mapbox://styles/lukezcrouch/clh735qte00ab01rh50maehbh"}
      mapStyle={"mapbox://styles/aeropath/cloyxyjg100bw01r7h7t1cjlk"}
      // mapStyle={"mapbox://styles/mapbox/light-v10"}
      // mapStyle={
      //   "mapbox://styles/aeropath/clmfbxiga017101rfdhf0frlj?access_token=pk.eyJ1IjoiYWVyb3BhdGgiLCJhIjoiY2xtZmVmeDFzMDRzcjNlanl3cnFlb2oyNiJ9.EUWvC8xHPgUaFrrJ_kawng"
      // }
      logoPosition={"bottom-right"}
      style={{ width: mapSize, right: 0, position: "fixed" }}
    >
      <Flex
        zIndex={300}
        left="220px"
        bottom="5px"
        width="200px"
        position="fixed"
      ></Flex>
      <Symbols />
      {symbolsLoaded && <Layers />}
      <Controls />
      <Actions />
    </MapInstance>
  );
}
