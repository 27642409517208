import React, { useState, useEffect } from "react";
import AltSlider from "./slider";
import ImagerySwitcher from "./ImagerySwitcher";
import LayerSwitcher, {
  AdsbToggle,
  FixesToggle,
  NavaidToggle,
  VhfToggle,
  AerodromeToggle,
  SectorToggle,
} from "./LayerSwitcher";
import {
  Flex,
  Center,
  IconButton,
  Text,
  Box,
  useColorModeValue,
  Collapse,
  VStack,
  SlideFade,
  LightMode,
} from "@chakra-ui/react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { isMobileOnly, isIOS } from "react-device-detect";
import { NavigationControl } from "react-map-gl";
import { MdOutlineExpandMore } from "react-icons/md";
import {
  layerState,
  layerStateHandler,
  mapControlsExpandedState,
  selectedAltitudeState,
} from "../../globalState";

export default function Controls() {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const [mapControlsExpanded, setMapControlsExpanded] = useRecoilState(
    mapControlsExpandedState
  );
  const altitude = useRecoilState(selectedAltitudeState);

  const [showImagerySwitcher, setShowImagerySwitcher] = useState(false);
  const [showLayerSwitcher, setShowLayerSwitcher] = useState(false);

  // Chakra light and dark mode
  const collapseButtonHover = useColorModeValue("white", "gray.700");
  const collapseButtonHoverOpacity = useColorModeValue("0.9", "1");
  const collapseButtonBg = useColorModeValue("white", "gray.700");
  const collapseButtonColor = useColorModeValue("gray.700", "gray.200");
  const dropDownBg = useColorModeValue(
    "rgba(20,25,30, 0.15)",
    "rgba(20,25,30, 0.22)"
  );

  const smallScreenSize = window.innerWidth < 768;
  const isIphone = isMobileOnly && isIOS;

  const fadeNavControls =
    showImagerySwitcher || showLayerSwitcher
      ? smallScreenSize
        ? "0.1"
        : "0.2"
      : "1";

  const mapboxStyleOveride = {
    position: "relative",
    inset: "auto",
    marginBottom: "0px",
    boxShadow: "none",
    border: "none",
    opacity: fadeNavControls,
    transition: "opacity ease-in 250ms",
  };

  useEffect(() => {
    if (!smallScreenSize) {
      setShowLayerSwitcher(false);
    }
  }, [smallScreenSize]);

  useEffect(() => {
    // if any of the layers are selected, then toggle on the respective map symbols
    if (
      !(layers.adsb_sites && layers.adsb_sites_selected) &&
      (layers.adsb || layers.adsb_by_site)
    ) {
      layerHandler("adsb_sites");
      layerHandler("adsb_sites_selected");
    }
    if (
      !(layers.vhf_sites && layers.vhf_sites_selected) &&
      (layers.vhf_combined || layers.vhf)
    ) {
      layerHandler("vhf_sites");
      layerHandler("vhf_sites_selected");
    }
    if (!layers.navaid && (layers.navaid_combined || layers.navaid_coverage)) {
      layerHandler("navaid");
    }
  }, [layers, layerHandler]);

  function altitudeFormatter(val) {
    const altitude = val[0];
    return altitude === 0
      ? "30 ft AGL"
      : `${
          Math.round(altitude) >= 10000
            ? Math.round(altitude).toLocaleString("en")
            : Math.round(altitude)
        } ft AMSL`;
  }

  const noFocus = {
    _focus: { boxShadow: "none" },
    _focusVisible: { boxShadow: "none !important" },
  };

  return (
    <>
      <Flex
        zIndex={"10"}
        position="absolute"
        top="26px"
        right="85px"
        color="white"
        fontSize="0.9rem"
        fontWeight="500"
        textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
      >
        <Text>{altitudeFormatter(altitude)}</Text>
      </Flex>

      <Flex
        id={"mapControls"}
        direction={"column"}
        position={"absolute"}
        borderRadius={"20px"}
        justifyContent={"center"}
        w={{ base: "40px", md: "38px" }}
        top={"15px"}
        right={"15px"}
        bgColor={dropDownBg}
        backdropFilter="blur(3px)"
        boxShadow="inset 0px 0px 15px -7px rgba(0, 0, 0, 0.1)"
      >
        <Center
          w="100%"
          h="40px"
          borderRadius={"20px"}
          boxShadow={useColorModeValue(
            "0px 4px 12px -5px rgba(0, 0, 0, 0.3)",
            "0px 2px 20px -8px rgba(255,255,255,0.3)"
          )}
        >
          <IconButton
            w="40px"
            h="40px"
            minW="40px"
            transform={mapControlsExpanded && "rotate(180deg)"}
            style={{ transition: "transform 0.2s" }}
            fontSize="1.6rem"
            borderRadius={"20px"}
            background={collapseButtonBg}
            color={collapseButtonColor}
            opacity={useColorModeValue("1", "0.9")}
            _hover={{
              opacity: collapseButtonHoverOpacity,
              backgroundColor: collapseButtonHover,
            }}
            _active={{
              opacity: collapseButtonHoverOpacity,
              backgroundColor: collapseButtonHover,
            }}
            icon={<MdOutlineExpandMore />}
            onClick={() => setMapControlsExpanded(!mapControlsExpanded)}
            {...noFocus}
          />
        </Center>

        <NavigationControl
          className={mapControlsExpanded ? "" : "nav-hidden"}
          style={{ ...mapboxStyleOveride }}
        />

        <LightMode>
          <Collapse
            animateOpacity
            in={mapControlsExpanded}
            style={{
              overflow: "initial",
            }}
          >
            <Flex
              flexDir="column"
              alignItems="center"
              pt={smallScreenSize ? "185px" : "113px"}
              opacity={fadeNavControls}
            >
              {!smallScreenSize && (
                <VStack
                  spacing={{
                    base: "0.85rem",
                    md: "0.85rem",
                    lg: "3",
                  }}
                  // pb="20px"
                >
                  <SlideFade
                    zIndex={
                      showImagerySwitcher || showLayerSwitcher ? "1" : "2"
                    }
                    offsetY="20px"
                    animateOpacity
                    in={!showImagerySwitcher && !showLayerSwitcher}
                    style={{ overflow: "initial" }}
                  >
                    <Flex
                      pt={{
                        base: "15px",
                        md: "13px",
                      }}
                      marginTop="30px"
                      flexDir="column"
                      alignItems="center"
                    >
                      <VStack
                        spacing={{
                          base: "0.85rem",
                          md: "0.85rem",
                          lg: "3",
                        }}
                      >
                        <AerodromeToggle />

                        <AdsbToggle />
                      </VStack>
                    </Flex>
                  </SlideFade>

                  <VhfToggle />

                  <NavaidToggle />

                  <FixesToggle />

                  <SectorToggle />
                </VStack>
              )}
              <Flex
                w="100%"
                h={
                  isIphone
                    ? "calc(100vh - 455px)"
                    : smallScreenSize
                    ? "calc(100vh - 430px)"
                    : "calc(100vh - 525px)"
                }
                maxHeight={
                  isIphone
                    ? "calc(100vh - 455px)"
                    : smallScreenSize
                    ? "calc(100vh - 430px)"
                    : "calc(100vh - 525px)"
                }
                marginTop="25px"
                marginBottom="20px"
                direction="column"
                opacity={mapControlsExpanded ? "1" : "0"}
                visibility={mapControlsExpanded ? "visible" : "hidden"}
                transition="all ease 250ms"
              >
                <AltSlider mapControlsExpanded={mapControlsExpanded} />
              </Flex>
            </Flex>
          </Collapse>
        </LightMode>
      </Flex>

      <LightMode>
        <Flex
          opacity={
            smallScreenSize && showLayerSwitcher
              ? "0.1"
              : mapControlsExpanded
              ? "1"
              : "0"
          }
          visibility={mapControlsExpanded ? "visible" : "hidden"}
          transition="all linear 200ms"
          zIndex={!showImagerySwitcher ? "1" : "1401"}
          flexDir="column"
          alignItems="flex-end"
          position="absolute"
          top="170px"
          right="19px"
        >
          <ImagerySwitcher
            show={showImagerySwitcher}
            setShow={setShowImagerySwitcher}
          />
        </Flex>

        {smallScreenSize && (
          <Flex
            opacity={mapControlsExpanded ? "1" : "0"}
            visibility={mapControlsExpanded ? "visible" : "hidden"}
            transition="all linear 200ms"
            zIndex={!showLayerSwitcher ? "1" : "1401"}
            flexDir="column"
            alignItems="flex-end"
            position="absolute"
            top="211px"
            right="19px"
          >
            <LayerSwitcher
              show={showLayerSwitcher}
              setShow={setShowLayerSwitcher}
            />
          </Flex>
        )}
      </LightMode>

      <Box // Shaded background behind Slide Up and Slide Down Drawers.
        className="closeOverlay"
        background={
          showImagerySwitcher || showLayerSwitcher
            ? "rgba(0,0,0,0.5)"
            : "transparent"
        }
        transition="all ease-in 350ms"
        transitionDelay="0ms"
        height="100%"
        width="100%"
        visibility={
          showImagerySwitcher || showLayerSwitcher ? "visible" : "hidden"
        }
        display={{
          base: "flex",
          md: "flex",
        }}
        zIndex={showImagerySwitcher || showLayerSwitcher ? "1400" : "0"}
        position="fixed"
        top="0px"
        left="0"
        marginTop="0 !important"
        onClick={() => {
          setShowImagerySwitcher(false);
          setShowLayerSwitcher(false);
        }}
      ></Box>
    </>
  );
}
