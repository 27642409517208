// COMPONENT PARTS CAN BE IDENTIFIED HERE:
// client > node_modeuls > @chakra-ui > theme > dist > declarations > src > components

import { mode } from "@chakra-ui/theme-tools";
// import { switchAnatomy } from '@chakra-ui/anatomy'
// import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { isMobileOnly, isIOS } from "react-device-detect";
// const { definePartsStyle, defineMultiStyleConfig } =
//   createMultiStyleConfigHelpers(switchAnatomy.keys)

//   const baseStyle = definePartsStyle({
//     // define the part you're going to style
//     container: {
//       // ...
//     },
//     thumb: {
//       bg: 'red.500',
//     },
//     track: {
//       bg: 'gray.600',
//     },
//   })

// export const switchTheme = defineMultiStyleConfig({ baseStyle })

// Switch
export const switchStyles = {
  parts: ["track", "thumb"],
  colorScheme: "cyan",
  baseStyle: {
    track: {
      bg: "gray.600",
      width: "1.375rem",
      height: "0.75rem",
      _focus: {
        border: "none",
        boxShadow: "none",
      },
      _checked: {
        background: "cyan.400",
      },
    },
    thumb: {
      bg: "gray.300",
      width: "0.75rem",
      height: "0.75rem",
      _focus: {
        border: "none",
        boxShadow: "none",
      },
    },
    colorScheme: {
      active: (props) => ({
        background: "cyan.400",
      }),
      normal: (props) => ({
        background: "gray.800",
      }),
      adsb: (props) => ({
        background: "adsb.400",
      }),
      vhf: (props) => ({
        background: "vhf.400",
      }),
      navaid: (props) => ({
        background: "navaid.400",
      }),
    },
  },
};

// Checkbox
export const checkboxStyles = {
  parts: ["label", "icon", "container", "control"],
  colorScheme: "teal",
  baseStyle: {
    control: {
      borderRadius: "100%",
      border: "2px solid",
      borderColor: "#d5deea",
      padding: "3px",
      width: { base: "24px", md: "20px" },
      height: { base: "24px", md: "20px" },
      fontSize: { base: "0.8rem", md: "0.7rem" },
      _focus: {
        boxShadow: "none",
      },
      _checked: {
        background: "cyan.400",
        borderColor: "cyan.400",
      },
      icon: {
        width: { base: "1rem", md: "0.9rem" },
      },
      label: {
        width: { base: "1rem", md: "0.9rem" },
      },
    },
  },
  variants: {
    desktop: (props) => ({
      control: {
        padding: "2px",
        width: "14px",
        height: "14px",
        minHeight: "14px",
        borderRadius: "100%",
        border: "2px solid",
        borderColor: "#d5deea",
        _focus: {
          background: "cyan.500",
        },
        _hover: {
          background: "cyan.500",
        },
        _active: {
          background: "cyan.500",
        },
        _checked: {
          background: "cyan.400",
          borderColor: "cyan.400",
        },
      },
    }),
    mobile: (props) => ({
      control: {
        width: "26px",
        height: "26px",
        minHeight: "26px",
        borderRadius: "100%",
        border: "2px solid",
        borderColor: "#d5deea",
        _checked: {
          background: "cyan.400",
          borderColor: "cyan.400",
        },
      },
    }),
  },
};

// Inputs (Form Fields)
export const inputStyles = {
  parts: ["element", "field", "addon"],
  colorScheme: "teal",
  baseStyle: {
    field: {
      border: "1px solid",
      borderRadius: "12px",
      _focus: {
        borderColor: "cyan.400",
      },
    },
  },
  variants: {
    primary: (props) => ({
      field: {
        border: "1px solid",
        borderColor: "gray.400",
        borderRadius: "12px",
        background: "#FDFEFF",
        _focus: {
          borderColor: "cyan.400",
        },
      },
      element: {
        color: "cyan.400",
      },
      addon: {
        color: "cyan.400",
      },
    }),
  },
};

// Modals
export const modalStyles = {
  parts: [
    "dialogContainer",
    "dialog",
    "overlay",
    "header",
    "body",
    "footer",
    "closeButton",
  ],
  baseStyle: {
    dialogContainer: {
      zIndex: "1400",
      height: "100%",
      minHeight: "100%",
      paddingBottom: {
        base: isMobileOnly && isIOS ? "85px" : "70px",
        md: "0",
      },
      width: { base: "100%", md: "calc(100% - 50px)" },
      marginLeft: { base: "0px", md: "50px" },
      right: "0px",
    },
    dialog: {
      borderRadius: 20,
      boxShadow: "0px 15px 50px -20px rgba(0,0,0,0.5)",
      overflow: "hidden",
      my: "auto",
      mx: { base: "15px", md: "auto" },
    },
    overlay: {
      zIndex: "unset",
      left: "unset",
      right: 0,
      width: { base: "100%", md: "calc(100% - 50px)" },
      marginRight: "0px",
      height: {
        base: isMobileOnly && isIOS ? "calc(100% - 85px)" : "calc(100% - 70px)",
        md: "100%",
      },
      minHeight: {
        base: isMobileOnly && isIOS ? "calc(100% - 85px)" : "calc(100% - 70px)",
        md: "100%",
      },
    },
    header: {
      bg: mode("#4a5568"),
      paddingStart: "5",
      paddingEnd: "4",
      paddingTop: "3",
      paddingBottom: "3",
      fontSize: "1.1rem",
      minHeight: "60px",
      alignItems: "center",
      display: "flex",
      width: "100%",
    },
    closeButton: {
      zIndex: "4",
      fontSize: "1rem",
      color: mode("gray.400", "gray.800"),
      top: "14px",
      right: "14px",
      opacity: "0.7",
      _hover: {
        opacity: "1",
      },
      _focus: {
        border: "none",
      },
    },
  },

  variants: {
    primary: (props) => ({
      dialogContainer: {
        boxShadow: "0px 15px 50px -20px rgba(10,20,30,0.5)",
        paddingBottom: {
          base: isMobileOnly && isIOS ? "85px" : "70px",
          md: "0",
        },
      },
      header: {
        zIndex: "3",
        bg: mode("white", "gray.700")(props),
        textColor: mode("gray.800", "gray.100")(props),
        fontWeight: "600",
        boxShadow: mode(
          "0px 0px 15px -1px rgba(0,0,0,0.15)",
          "0px 0px 15px -1px rgba(0,0,0,0.3)"
        )(props),
        borderBottom: "1px solid",
        borderColor: mode("gray.200", "gray.800")(props),
      },
      body: {
        zIndex: "1",
        bg: mode("white", "#364153")(props),
        fontSize: "0.9rem",
        color: mode("gray.800", "white")(props),
      },
      footer: {
        zIndex: "2",
        bg: mode("white", "gray.700")(props),
        borderTop: "1px solid",
        borderColor: mode("gray.200", "gray.800")(props),
      },
    }),
    alert: () => ({
      dialogContainer: {
        zIndex: "1400",
      },
      dialog: {
        zIndex: "1199",
      },
      overlay: {
        zIndex: "1199",
      },
    }),
    fullscreen: (props) => ({
      dialogContainer: {
        zIndex: "1500",
        height: "100%",
        minHeight: "100%",
        paddingBottom: {
          base: "0px",
          md: "0",
        },
        width: { base: "100%", md: "calc(100% - 50px)" },
        marginLeft: { base: "0px", md: "50px" },
        right: "0px",
        overflow: "hidden",
      },
      dialog: {
        zIndex: "1500",
        height: { base: "calc(100% - 47px)", md: "calc(100% - 30px)" },
        maxHeight: {
          base: "calc(100% - 47px)",
          md: "calc(100% - 30px)",
        },
        width: { base: "100%", md: "calc(100% - 30px)" },
        minWidth: { base: "100%", md: "calc(100% - 30px)" },
        maxWidth: { base: "100%", md: "calc(100% - 30px)" },
        marginBottom: { base: "0px", md: "auto" },
        marginInlineStart: {
          base: "0px !important",
          md: "15px !important",
        },
        marginInlineEnd: {
          base: "0px !important",
          md: "15px !important",
        },
        borderBottomRadius: { base: "0px", md: "20px" },
        overflow: "hidden",
      },
      overlay: {
        zIndex: "1499",
      },
      closeButton: {
        top: "2",
        right: "2",
      },
    }),
  },
};

// Drawer
export const drawerStyles = {
  parts: ["dialogContainer", "dialog", "header", "closeButton"],
  baseStyle: {
    closeButton: {
      fontSize: "1rem",
      color: mode("white", "gray.800"),
      top: "3",
      right: "3",
      opacity: "0.7",
      _hover: {
        opacity: "1",
      },
      _focus: {
        border: "none",
      },
    },
    body: {
      fontSize: "0.9rem",
      color: mode("gray.800", "gray.200"),
    },
  },
};

// Slider
export const sliderStyles = {
  parts: ["track", "container", "thumb", "filledTrack", "marker"],
  baseStyle: {
    filledTrack: {
      background: "cyan.400",
      borderRadius: "5px",
    },
    track: {
      height: "6px",
      borderRadius: "5px",
    },
  },
};

// Headings
export const headingStyles = {
  baseStyle: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: "white",
  },
  variants: {
    primary: (props) => ({
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: "white",
      // need to add light and dark mode color options as a test
    }),
    mainHeading: (props) => ({
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: "white",
      // need to add light and dark mode color options as a test
    }),
    subHeading: (props) => ({
      fontSize: "0.85rem",
      fontWeight: "600",
      paddingTop: "1",
      paddingBottom: "1px",
      color: "white",
      // need to add light and dark mode color options as a test
    }),
  },
};

// Badge
export const badgeStyles = {
  colorScheme: {
    ifr: (props) => ({
      background: "yellow",
    }),
    vfr: (props) => ({
      background: "red",
      // need to add light and dark mode color options as a test
    }),
  },
};

// Dividers
export const dividerStyles = {
  baseStyle: {
    borderColor: "gray.200",
    borderStyle: "solid",
  },
  variants: {
    primary: {
      marginTop: "15px",
      marginBottom: "15px",
      borderColor: "gray.200",
      borderStyle: "solid",
    },
    modalFooter: {
      marginTop: "15px",
      marginBottom: "15px",
      borderColor: "gray.200",
      borderStyle: "solid",
    },
    altitidues: {
      marginTop: "4px",
      marginBottom: "4px",
      borderStyle: "solid",
      width: "80px",
      borderColor: "#4A5568",
      opacity: "1",

      // need to add light and dark mode color options as a test
    },
  },
};

// Tables
export const tableStyles = {
  parts: ["Tbody", "Tr", "Td"],

  // style object for base or default style
  baseStyle: {},

  // styles for different sizes ("sm", "md", "lg")
  sizes: {},

  variants: {
    striped: (props) => ({
      th: {
        fontFamily: "Open Sans",
        fontWeight: "700",
        fontSize: "0.7rem",
        textAlign: "center",
        px: "5px",
      },
      td: {
        fontFamily: "Open Sans",
        fontWeight: "normal",
        fontSize: "0.7rem",
        textAlign: "center",
        px: "5px",
      },
    }),
    normal: (props) => ({
      td: {
        paddingStart: "1",
        paddingEnd: "1",
        paddingTop: "1",
        paddingBottom: "1",
        fontFamily: "Open Sans",
        letterSpacing: "0",
        lineHeight: "1.2",
        fontWeight: "normal",
        verticalAlign: "baseline",
      },
    }),
  },

  // default values for `size` and `variant`
  defaultProps: {
    size: "",
    variant: "",
  },
};

// Buttons
export const brandRing = {
  _focus: {
    ring: 2,
    ringColor: "cyan.500",
  },
};
export const buttonStyles = {
  variants: {
    primary: (props) => ({
      rounded: "none",
      ...brandRing,
      color: mode("white", "gray.800")(props),
      backgroundColor: mode("cyan.400", "cyan.400")(props),

      _hover: {
        backgroundColor: mode("cyan.500", "cyan.300")(props),
      },

      _active: {
        backgroundColor: mode("cyan.600", "cyan.300")(props),
      },
    }),
  },
};
