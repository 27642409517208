import React from "react";
import { Source, Layer } from "react-map-gl";

export default function Aerodromes() {
  const dataLayer = {
    id: "ad",
    type: "symbol",
    layout: {
      "icon-image": ["get", "icon"],

      "icon-size": [
        "match",
        ["get", "icon"],
        "advfr",
        0.6,
        "adifr",
        0.6,
        "adnonpub",
        0.6,
        "adwater",
        0.6,
        1,
      ],
      "text-field": ["get", "designator"],
      "text-offset": [0, 2],
      "text-size": 10,
      "text-font": ["Montserrat SemiBold"],
      "symbol-sort-key": 1,
    },

    paint: {
      "text-color": "#000000",
      "text-halo-width": 1.5,
      "text-halo-color": "#ffffff",

      // "icon-opacity": [
      //   "interpolate",
      //   ["linear"],
      //   ["zoom"],
      //   6,
      //   ["match", ["get", "icon"], "adifr", 1, 0],
      //   8,
      //   ["match", ["get", "icon"], "adifr", 1, "advfr", 1, 0],
      //   10,
      //   1,
      // ],
      "text-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        6,
        ["match", ["get", "icon"], "adifr", 1, 0],
        8,
        ["match", ["get", "icon"], "adifr", 1, "advfr", 1, 0],
        10,
        1,
      ],
    },
  };

  return (
    <Source type="geojson" data={`${window.location.origin}/data/ad`}>
      <Layer {...dataLayer} />
    </Source>
  );
}
