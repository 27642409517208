import React from "react";
import { Source, Layer } from "react-map-gl";

export default function Navaids() {
  const dataLayer = {
    id: "navaids",
    type: "symbol",
    layout: {
      "icon-image": ["get", "type"],
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "icon-size": 0.8,

      "text-field": ["get", "designator"],
      "text-offset": [0, 2],
      "text-size": 11,
      "text-font": ["Montserrat SemiBold"],
      "symbol-sort-key": 100,
      "symbol-spacing": 1,
    },

    paint: {
      "text-color": "#000000",
      "text-halo-width": 1.5,
      "text-halo-color": "#ffffff",
      "text-opacity": ["interpolate", ["linear"], ["zoom"], 5, 0, 6, 1],
    },
  };

  return (
    <Source type="geojson" data={`${window.location.origin}/data/navaids`}>
      <Layer {...dataLayer} />
    </Source>
  );
}
