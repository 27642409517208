import React from "react";
import { Source, Layer } from "react-map-gl";
import { selectedAltitudeState, navaidsSelectedState } from "../../globalState";
import { useRecoilValue } from "recoil";

export default function NavaidCoverage() {
  const selectedAltitude = useRecoilValue(selectedAltitudeState);
  const navaidsSelected = useRecoilValue(navaidsSelectedState);
  const dataLayer = {
    id: "navaid",
    beforeId: "continent-label",
    type: "fill",
    layout: {},
    filter: [
      "all",
      ["==", "altitude", selectedAltitude],
      ["in", "navid", ...navaidsSelected],
    ],
    paint: {
      "fill-color": [
        "match",
        ["get", "type"],
        "VOR",
        "#E5BF00",
        "DME",
        "#F66902",
        "#ffffff",
      ],

      "fill-opacity": 0.5,
    },
  };

  return (
    <Source
      type="geojson"
      data={`${window.location.origin}/data/coverage/navaid`}
    >
      <Layer {...dataLayer} />
    </Source>
  );
}
