import React from "react";
import { useRecoilValue } from "recoil";
import { Source, Layer } from "react-map-gl";
import { layerState } from "../../../globalState";

export default function VNCLayer() {
  const layers = useRecoilValue(layerState);

  //TODO Test - This useEffect takes a ref of the state of the mapbox layers when the VNC layer is turned on, and subsequently hides the underlying mapbox layers.
  // Just wondering if there's anything else that turns off / on visibilty of mapbox layers, and therefore this ref could mutate over time if users are using other functionality in the app
  //   useEffect(() => {
  //     const layers = mapInstance.getStyle().layers;
  //     if (layerSelection.vnc) {
  //       layerVisibilityRef.current = layers.map((layer) => ({
  //         id: layer.id,
  //         source: layer.source,
  //         layout: {
  //           visibility:
  //             layer.layout && layer.layout.visibility
  //               ? layer.layout.visibility
  //               : undefined,
  //         },
  //       }));
  //       layers.forEach((layer) => {
  //         layer.id !== "water" &&
  //           layer.source === "composite" &&
  //           mapInstance.setLayoutProperty(layer.id, "visibility", "none");
  //       });
  //     }

  //     if (!layerSelection.constant.vnc && layerVisibilityRef.current) {
  //       layerVisibilityRef.current.forEach((layer) => {
  //         layer.id !== "water" &&
  //           layer.source === "composite" &&
  //           mapInstance.setLayoutProperty(
  //             layer.id,
  //             "visibility",
  //             layer.layout.visibility
  //           );
  //       });
  //     }
  //   }, [layerSelection.constant.vnc, mapInstance]);

  if (layers.vnc) {
    return (
      <>
        <Source
          id="vnc-500k"
          type="raster"
          url="mapbox://aeropath.NZ_eVNC_500k_22-12?access_token=pk.eyJ1IjoiYWVyb3BhdGgiLCJhIjoiY2pkM2t1dHk2MDNrMjMxcnowY2c0OGJrcCJ9.lwac7JVXlynU2HTOKu7_QA"
        />
        <Source
          id="vnc-250k"
          type="raster"
          url="mapbox://aeropath.NZ_eVNC_250k_22-12?access_token=pk.eyJ1IjoiYWVyb3BhdGgiLCJhIjoiY2pkM2t1dHk2MDNrMjMxcnowY2c0OGJrcCJ9.lwac7JVXlynU2HTOKu7_QA"
        />
        <Layer
          id="vnc-500k"
          beforeId="country-label"
          type="raster"
          source="vnc-500k"
          before="country-label"
          maxZoom={9}
          paint={{
            "raster-saturation": -0.33,
            "raster-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              9,
              1,
              10,
              0,
            ],
          }}
        />
        <Layer
          id="vnc-250k"
          beforeId="country-label"
          type="raster"
          source="vnc-250k"
          before="vnc-500k"
          minZoom={9}
          paint={{
            "raster-saturation": -0.33,
            "raster-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              9,
              0,
              9.5,
              1,
            ],
          }}
        />
      </>
    );
  }
  return null;
}
