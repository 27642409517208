// Dynamically loads SVG icons for use in react-map-gl
import { useEffect } from "react";
import { useMap } from "react-map-gl";
import { useSetRecoilState } from "recoil";

import { symbolsLoadedState } from "../../globalState";

export default function Symbols() {
  const { current: map } = useMap();
  const mapInstance = map.getMap();

  const setLoaded = useSetRecoilState(symbolsLoadedState);

  // Load icons once only on load
  useEffect(() => {
    // Creates HTML image element and attaches svg data to it.
    const loadSvgAsHtmlImage = (dataURI) => {
      return new Promise((resolve) => {
        const image = new Image(32, 32);
        image.addEventListener("load", () => {
          image.setAttribute(
            "width",
            Math.max(1, Math.floor(image.naturalWidth))
          );
          image.setAttribute(
            "height",
            Math.max(1, Math.floor(image.naturalHeight))
          );
          return resolve(image);
        });
        image.src = dataURI && dataURI.default ? dataURI.default : dataURI;
        // when compiled with webpack the dataURI is imported as a react component, therefore needs to be referred to as dataURI.default
      });
    };

    // Creates an svgs object that lists the name of the svg, and returns an HTML image element from loadSvgAsHtmlImage.
    const importAll = async (r) => {
      const svgs = {};

      return new Promise((resolve) => {
        r.keys().map(async (item, index) => {
          var key = item
            .substring(item.lastIndexOf("/") + 1)
            .replace(".svg", "");

          // Load SVG as HTML Image, then add to svgs object, then if the last svg, resolve promise
          loadSvgAsHtmlImage(r(item))
            .then((data) => (svgs[key] = data))
            .then(() => {
              if (r.keys().length - 1 === index) {
                resolve();
              }
            });
        });
      }).then(() => {
        return svgs;
      });
    };

    // This imports all svg icons in the recursive directories and makes them available to mapbox (mapbox id will match filename)
    // Regex for other image types: /\.(png|jpe?g|svg)$/
    const loadSvgs = importAll(require.context("./", true, /\.svg$/));

    // Load the SVGs then setIcons with react-mapbox-gl formatted Image (renamed Icon)
    loadSvgs.then((svgs) =>
      Object.keys(svgs).forEach((icon, index) => {
        if (!mapInstance.hasImage(icon)) {
          mapInstance.addImage(icon, svgs[icon]);
          if (index === Object.keys(svgs).length - 1) {
            setLoaded(true);
          }
        }
      })
    );
  }, [mapInstance, setLoaded]);

  return null;
}
