import {
  extendTheme,
  withDefaultColorScheme,
  withDefaultVariant,
} from "@chakra-ui/react";

import {
  modalStyles as Modal,
  drawerStyles as Drawer,
  headingStyles as Heading,
  badgeStyles as Badge,
  dividerStyles as Divider,
  tableStyles as Table,
  buttonStyles as Button,
  checkboxStyles as Checkbox,
  switchStyles as Switch,
  sliderStyles as Slider,
  inputStyles as Input,
} from "./components/componentStyles";

const inputSelectStyles = {
  variants: {
    filled: {
      field: {
        _focus: {
          borderColor: "cyan.400",
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: "none",
      },
    },
  },
};

const theme = extendTheme(
  {
    initialColorMode: "dark",
    cssVarPrefix: "coverageMapper",

    // THEME COLORS
    colors: {
      vhf: {
        500: "#ED4C82",
      },
      proposedVhf: {
        500: "#b30000",
      },
      adsb: {
        500: "#50b1ff",
      },
      ssr: {
        500: "#03d8c3",
      },
      vor: {
        500: "#E5BF00",
        600: "#ddb900",
      },
      dme: {
        500: "#ff9f57",
        600: "#ff9343",
      },
      vordme: {
        500: "#f3b43e",
      },
      sector: {
        500: "#3A3B3C",
      },
      light: {
        100: "#2A3744",
        200: "#00D599",
      },
      dark: {
        100: "#CED4DC",
        200: "#1deeaa",
      },
      // BADGES
      ifr: {
        100: "#92278f", // Purple
      },
      vfr: {
        100: "#0087bc", // Blue
      },
    },
    fonts: {
      heading: "Open Sans",
      body: "Open Sans",
    },

    // OVERALL BORDER RADIUS
    radii: {
      none: "0",
      sm: "0.125rem",
      base: "0.25rem",
      md: "0.375rem",
      lg: "0.5rem",
      xl: "0.75rem",
      "2xl": "1rem",
      "3xl": "1.5rem",
      full: "9999px",
    },

    // BREAKPOINT VALUES (Responsive styling)
    // DO NOT CHANGE!!! as this will likely break responsive behaviours
    // Most components are set from mobile to change at "md" to desktop view
    breakpoints: {
      xs: "25em", // 400px (custom)
      xsm: "27em", // 432px (custom)
      sm: "30em", // 480px - DO NOT CHANGE!!
      smd: "36em", // 576px (custom)
      md: "48em", // 768px - DO NOT CHANGE!!
      ml: "54em", // 864px (custom)
      lg: "62em", // 992px - DO NOT CHANGE!!
      xl: "80em", // 1280px - DO NOT CHANGE!!
      "2xl": "96em", // 1536px - DO NOT CHANGE!!
      "3xl": "110em", // 1760px (custom)
    },

    // REMOVE FOCUS FROM ALL BUTTONS
    _focus: {
      border: "none",
    },

    // HEADING STYLES
    styles: {
      global: {
        h1: {
          fontWeight: "700",
          lineHeight: "1.3",
          fontSize: "1.2rem",
          fontFamily: ["Poppins", "Open Sans", "sans-serif"],
        },
        h2: {
          fontSize: "1.05rem",
          fontWeight: "600",
          lineHeight: "1.3",
          fontFamily: ["Poppins", "Open Sans", "sans-serif"],
          letterSpacing: "1%",
        },
        h3: {
          fontSize: "0.95rem",
          fontWeight: "500",
          lineHeight: "1.3",
          fontFamily: ["Poppins", "Open Sans", "sans-serif"],
          letterSpacing: "1%",
        },
        normal: {
          fontSize: "0.9rem",
          fontWeight: "400",
          lineHeight: "1.3",
          fontFamily: ["Open Sans", "sans-serif"],
        },
      },
    },

    //COMPONENTS (Imported from ComponentStyles file)
    components: {
      Table,
      Button,
      Modal,
      Drawer,
      Heading,
      Badge,
      Divider,
      Switch,
      Checkbox,
      Slider,
      Input,
      Select: { ...inputSelectStyles },
    },
  },
  withDefaultColorScheme({
    colorScheme: "cyan",
    components: ["Checkbox", "Modal"],
  }),
  withDefaultVariant({
    variant: "filled",
    components: ["Input", "Select"],
  })
);

export default theme;
