import React from "react";
import {
  Flex,
  Button,
  Slide,
  Box,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { isMobileOnly, isIOS } from "react-device-detect";
import {
  layerState,
  layerStateHandler,
  navaidTypeSelectedState,
  navaidTypeOpenState,
  navaidsSelectedState,
} from "../../globalState";
const navaidTypeList = ["VOR", "DME"];
export default function NavaidTypeSelector() {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);

  const [navaidTypeSelected, setNavaidTypeSelected] = useRecoilState(
    navaidTypeSelectedState
  );
  const setNavaidsSelected = useSetRecoilState(navaidsSelectedState);
  const navaidTypeOpen = useRecoilValue(navaidTypeOpenState);

  const drawerBg = useColorModeValue("gray.200", "#1A202A");
  const drawerItemColor = useColorModeValue("gray.800", "gray.300");
  const accordionButtonBgActive = useColorModeValue(
    "rgba(20, 50, 150, 0.04)",
    "rgba(255,255,255,0.04)"
  );
  const dividerColor = useColorModeValue(
    "rgba(20, 50, 150, 0.07)",
    "rgba(255,255,255,0.05)"
  );
  const slideTransition = useBreakpointValue({
    base: "bottom",
    md: "left",
  });
  const isIphone = isMobileOnly && isIOS;

  return (
    <>
      <Slide
        in={navaidTypeOpen}
        direction={slideTransition}
        style={{ pointerEvents: "none" }}
        zIndex="10"
      >
        {/* <Box
        height={{ base: "calc(100vh - 15px)", md: "100vh" }}
        borderTopRadius={{ base: "20px", md: "0px" }}
        width="270px"
        color="white"
        bg={drawerBg}
        marginLeft={{ base: "15px", md: "55px" }}
        pb={{ base: isIphone ? "75px" : "60px", md: "0px" }}
        boxShadow="0px 0px 20px 0px rgba(0,0,0,0.3)"
        zIndex="100"
        pointerEvents="auto"
        overflow="hidden"
      >

      </Box> */}
        <Box
          height="81px"
          borderTopLeftRadius={{ base: "10px", md: "0px" }}
          borderTopRightRadius="10px"
          borderBottomRightRadius={{ base: "0px", md: "10px" }}
          width={{ base: "20vw", sm: "75px", md: "55px" }}
          minWidth={{ base: "20vw", sm: "75px", md: "55px" }}
          color="white"
          bg={drawerBg}
          left={{ base: "unset", md: "55px" }}
          marginLeft={{ base: "auto", md: "unset" }}
          marginRight={{ base: "40vw", sm: "75px", md: "unset" }}
          top={{ base: "unset", md: "338px" }}
          bottom={{ base: isIphone ? "75px" : "60px", md: "unset" }}
          position={{ base: "relative", md: "absolute" }}
          pb={{ base: isIphone ? "75px" : "60px", md: "0px" }}
          zIndex="100"
          pointerEvents="auto"
          boxShadow={navaidTypeOpen && "0px 0px 20px 0px rgba(0,0,0,0.3)"}
          transition="all ease 200ms"
        >
          <Flex
            flexDirection="column"
            gap="0"
            width={{ base: "20vw", sm: "75px", md: "55px" }}
            minWidth={{ base: "20vw", sm: "75px", md: "55px" }}
            boxShadow={{
              base: "inset 0px -5px 15px -7px rgba(0, 0, 0, 0.15)",
              md: "inset 5px 0px 15px -7px rgba(0, 0, 0, 0.1)",
            }}
          >
            {navaidTypeList.map((type, i) => {
              return (
                <Button
                  key={type}
                  width="100%"
                  color={
                    navaidTypeSelected === type && type === "VOR"
                      ? "vor.600"
                      : navaidTypeSelected === type && type === "DME"
                      ? "dme.500"
                      : drawerItemColor
                  }
                  align="left"
                  fontFamily="Montserrat, sans-serif"
                  fontWeight="600"
                  fontSize="12"
                  variant="unstyled"
                  borderBottom={i === 0 && "1px solid"}
                  borderColor={dividerColor}
                  onClick={() => {
                    if (navaidTypeSelected === type) {
                      setNavaidTypeSelected("");
                    } else {
                      setNavaidTypeSelected(type);
                    }
                    if (
                      (layers.navaid_combined && navaidTypeSelected === type) ||
                      (!layers.navaid_combined && navaidTypeSelected === "")
                    ) {
                      layerHandler("navaid_combined");
                    }
                    if (layers.navaid_coverage && navaidTypeSelected === "") {
                      layerHandler("navaid_coverage");
                      setNavaidsSelected([]);
                    }
                  }}
                  _hover={{
                    fontSize: "13",
                    background: accordionButtonBgActive,
                  }}
                  transition="all ease 200ms"
                >
                  {type}
                </Button>
              );
            })}
          </Flex>
        </Box>
      </Slide>
    </>
  );
}
