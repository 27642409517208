import React from "react";
import { Source, Layer } from "react-map-gl";
import { selectedAltitudeState } from "../../globalState";
import { useRecoilValue } from "recoil";

export default function SsrCoverage() {
  const selectedAltitude = useRecoilValue(selectedAltitudeState);
  const dataLayer = {
    id: "ssr",
    beforeId: "continent-label",
    type: "fill",
    layout: { "fill-sort-key": 1 },
    filter: ["==", "altitude", selectedAltitude],
    paint: {
      "fill-color": "#03DAC5",
      "fill-opacity": 0.5,
    },
  };

  return (
    <Source type="geojson" data={`${window.location.origin}/data/coverage/ssr`}>
      <Layer {...dataLayer} />
    </Source>
  );
}
