import { Box, Flex, useColorModeValue, Button } from "@chakra-ui/react";
import { GiCheckMark } from "react-icons/gi";

// Menu Toggle Switches
export default function MenuSwitch(props) {
  const { activeColor, switchActive } = props;

  const toggleButtonColorOff = useColorModeValue("gray.300", "gray.700");
  const toggleButtonColorOffHover = useColorModeValue("gray.400", "gray.600");
  const toggleIndicatorOff = useColorModeValue("gray.100", "gray.500");
  const toggleIndicatorOn = useColorModeValue("white", "gray.200");

  const toggleTrack = {
    color: "#a8b2c0",
    variant: "outline",
    fontSize: "10px",
    fontWeight: "600",
    height: "18px",
    width: "36px",
    style: {
      minWidth: "36px",
    },
    justifyContent: "initial",
    paddingLeft: "2px",
    paddingRight: "2px",
    py: "2px",
    borderRadius: "20px",
    border: "none",
    boxShadow: "inset 0px 0px 3px -1px rgba(0,0,0,0.4)",
    _focus: {
      boxShadow: "inset 0px 0px 3px -1px rgba(0,0,0,0.4)",
    },
    _active: {
      background: "light.200",
      boxShadow: "inset 0px 0px 3px -1px rgba(0,0,0,0.4)",
    },
  };

  const toggleCheck = {
    mt: "0px",
    pl: "2px",
    mr: "-15px",
    color: "white",
    _selected: {
      opacity: "1",
    },
    _active: {
      opacity: "1",
    },
    transition: "opacity 300ms",
  };

  const toggleIndicator = {
    mt: "0px",
    ml: "0px",
    mr: "0px",
    height: "14px",
    width: "16px",
    borderRadius: "20px",
    boxShadow: "0px 0px 3px -1px rgba(0,0,0,0.3)",
    transition: "transform 200ms",
  };

  return (
    <Flex height="100%" justifyContent="center" alignItems="center">
      <Button
        marginTop="-1px"
        {...toggleTrack}
        background={switchActive ? activeColor : toggleButtonColorOff}
        _hover={{
          background: switchActive ? activeColor : toggleButtonColorOffHover,
        }}
        _focus={{
          background: switchActive ? activeColor : toggleButtonColorOffHover,
        }}
        _active={{
          background: switchActive ? activeColor : toggleButtonColorOffHover,
        }}
      >
        <Box {...toggleCheck} opacity={switchActive ? "0.8" : "0"}>
          <GiCheckMark fontSize="0.8rem" />
        </Box>
        <Box
          {...toggleIndicator}
          background={switchActive ? toggleIndicatorOn : toggleIndicatorOff}
          transform={switchActive ? "translateX(100%)" : "translateX(0%)"}
        ></Box>
      </Button>
    </Flex>
  );
}
