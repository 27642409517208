import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Slide,
  Box,
  useColorModeValue,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { isMobileOnly, isIOS } from "react-device-detect";
import {
  layerState,
  layerStateHandler,
  adsbSelectedState,
  adsbPanelOpenState,
} from "../../globalState";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import MenuSwitch from "../components/MenuSwitch";
import { GoDotFill } from "react-icons/go";

export default function AdsbSwitches() {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const [adsbList, setAdsbList] = useState([]);
  const [adsbSelected, setAdsbSelected] = useRecoilState(adsbSelectedState);

  const [adsbPanelOpen] = useRecoilState(adsbPanelOpenState);

  useEffect(() => {
    fetch(`${window.location.origin}/data/adsb_site_list`)
      .then((res) => res.json())
      .then((data) => setAdsbList(data));
  }, []);

  useEffect(() => {
    if (adsbSelected.length && !layers.adsb_by_site) {
      layerHandler("adsb_by_site");
    }
    if (!adsbSelected.length && layers.adsb_by_site) {
      layerHandler("adsb_by_site");
    }
  }, [adsbSelected, layers.adsb_by_site, layerHandler]);

  const drawerBg = useColorModeValue("gray.200", "#1A202A");
  const drawerItemColor = useColorModeValue("gray.800", "gray.300");
  const drawerItemHover = useColorModeValue(
    "rgba(20, 50, 150, 0.05)",
    "rgba(255,255,255,0.05)"
  );
  const drawerItemColorSelected = useColorModeValue("gray.900", "white");
  const dividerColor = useColorModeValue(
    "rgba(20, 50, 150, 0.07)",
    "rgba(255,255,255,0.05)"
  );

  const scrollBarHide = useBreakpointValue({
    base: "scroll",
    md: "leave",
  });

  const slideTransition = useBreakpointValue({
    base: "bottom",
    md: "left",
  });
  const isIphone = isMobileOnly && isIOS;

  // useEffect(() => {
  //   console.log("adsbSelected: ", adsbSelected);
  // }, [adsbSelected]);

  return (
    <Slide
      in={adsbPanelOpen}
      direction={slideTransition}
      style={{ pointerEvents: "none" }}
      zIndex="10"
    >
      <Box
        height={{
          base: isIphone ? "calc(100vh - 90px)" : "calc(100vh - 75px)",
          md: "100vh",
        }}
        borderTopLeftRadius={{ base: "20px", md: "0px" }}
        borderTopRightRadius={{ base: "20px", md: "0px" }}
        borderBottomRightRadius={{ base: "0px", md: "0px" }}
        width="270px"
        color="white"
        bg={drawerBg}
        marginLeft={{ base: "15px", md: "55px" }}
        pb={{ base: isIphone ? "75px" : "60px", md: "0px" }}
        boxShadow="0px 0px 20px 0px rgba(0,0,0,0.3)"
        zIndex="100"
        pointerEvents="auto"
        overflow="hidden"
      >
        <Flex
          flexDirection="column"
          height="100%"
          width="100%"
          boxShadow={{
            base: "inset 0px -5px 15px -7px rgba(0, 0, 0, 0.15)",
            md: "inset 5px 0px 15px -7px rgba(0, 0, 0, 0.1)",
          }}
        >
          <OverlayScrollbarsComponent
            options={{
              scrollbars: {
                visibility: "auto",
                autoHide: scrollBarHide,
                autoHideDelay: 800,
                dragScrolling: true,
                clickScrolling: true,
                touchSupport: true,
              },
              overflow: {
                x: "hidden",
                y: "scroll",
              },
            }}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <Flex
              flexDirection="column"
              gap="0"
              width="100%"
              pt="5px"
              pb="20px"
            >
              {adsbList.map((adsb_by_site) => {
                return (
                  <Flex
                    pl="20px"
                    pr="15px"
                    key={adsb_by_site.designator}
                    width="100%"
                    minHeight="30px"
                    justifyContent="space-between"
                    alignItems="center"
                    cursor="pointer"
                    borderBottom="1px solid"
                    borderColor={dividerColor}
                    _hover={{ bg: drawerItemHover }}
                    onClick={() =>
                      setAdsbSelected((prevState) => {
                        if (prevState.includes(adsb_by_site.designator)) {
                          return [...prevState].filter(
                            (item) => item !== adsb_by_site.designator
                          );
                        } else {
                          return [...prevState, adsb_by_site.designator];
                        }
                      })
                    }
                  >
                    <Icon
                      as={GoDotFill}
                      fontSize={
                        adsbSelected.includes(adsb_by_site.designator)
                          ? "1.1rem"
                          : "0rem"
                      }
                      transition="all ease 200ms"
                      color="adsb.500"
                      pr={
                        adsbSelected.includes(adsb_by_site.designator)
                          ? "5px"
                          : "0px"
                      }
                    />
                    <Text
                      color={
                        adsbSelected.includes(adsb_by_site.designator)
                          ? drawerItemColorSelected
                          : drawerItemColor
                      }
                      transition="all ease 200ms"
                      align="left"
                      fontFamily="Montserrat, sans-serif"
                      fontWeight={
                        adsbSelected.includes(adsb_by_site.designator)
                          ? "500"
                          : "400"
                      }
                      fontSize="0.75rem"
                      width="100%"
                      minWidth="180px"
                    >
                      {adsb_by_site.name} ({adsb_by_site.designator})
                    </Text>
                    <MenuSwitch
                      activeColor={"adsb.500"}
                      switchActive={adsbSelected.includes(
                        adsb_by_site.designator
                      )}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </OverlayScrollbarsComponent>
        </Flex>
      </Box>
    </Slide>
  );
}
