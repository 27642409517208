import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useMap } from "react-map-gl";
import {
  clickedFeatureState,
  navaidsSelectedState,
  vhfSelectedState,
  adsbSelectedState,
} from "../../globalState";

const interactiveLayers = ["navaids", "vhf_sites", "adsb_sites"];

export default function Click() {
  const { current: mapInstance } = useMap();

  const [clickedFeature, setClickedFeature] =
    useRecoilState(clickedFeatureState);
  const [navaidsSelected, setNavaidsSelected] =
    useRecoilState(navaidsSelectedState);
  const [vhfSelected, setVhfSelected] = useRecoilState(vhfSelectedState);
  const [adsbSelected, setAdsbSelected] = useRecoilState(adsbSelectedState);

  const onClick = useCallback(
    (e) => {
      const features = JSON.parse(JSON.stringify(e.features)); // hack to get around the read-only properties
      console.log(features);

      setClickedFeature({ ...features[0], prevZoom: mapInstance.getZoom() });
      mapInstance.flyTo({
        center: features[0].geometry.coordinates,
        zoom: 7,
      });

      if (features[0].layer.id === "navaids") {
        setNavaidsSelected((prevState) => {
          if (prevState.includes(features[0].properties.navid)) {
            return [...prevState].filter(
              (item) => item !== features[0].properties.navid
            );
          } else {
            return [...prevState, features[0].properties.navid];
          }
        });
        console.log(navaidsSelected);
      }

      if (features[0].layer.id === "vhf_sites") {
        setVhfSelected((prevState) => {
          if (prevState.includes(features[0].properties.designator)) {
            return [...prevState].filter(
              (item) => item !== features[0].properties.designator
            );
          } else {
            return [...prevState, features[0].properties.designator];
          }
        });
        console.log(vhfSelected);
      }

      if (features[0].layer.id === "adsb_sites") {
        setAdsbSelected((prevState) => {
          if (prevState.includes(features[0].properties.designator)) {
            return [...prevState].filter(
              (item) => item !== features[0].properties.designator
            );
          } else {
            return [...prevState, features[0].properties.designator];
          }
        });
        console.log(adsbSelected);
      }
    },

    [
      adsbSelected,
      vhfSelected,
      navaidsSelected,
      setClickedFeature,
      mapInstance,
      setNavaidsSelected,
      setVhfSelected,
      setAdsbSelected,
    ]
  );

  const offFeatureClick = useCallback(
    (e) => {
      if (clickedFeature) {
        const features = mapInstance.queryRenderedFeatures(e.point, {
          layers: interactiveLayers,
        });

        if (!features.length) {
          setClickedFeature(null);
          mapInstance.zoomTo(
            clickedFeature.prevZoom !== 10 ? clickedFeature.prevZoom : 8
          );
        }
      }
    },
    [clickedFeature, setClickedFeature, mapInstance]
  );

  const onHover = useCallback(
    (e) => {
      mapInstance.getCanvas().style.cursor = "pointer";
    },
    [mapInstance]
  );

  const offHover = useCallback(() => {
    mapInstance.getCanvas().style.cursor = "unset";
  }, [mapInstance]);

  useEffect(() => {
    mapInstance.on("click", interactiveLayers, onClick);
    mapInstance.on("click", offFeatureClick);
    mapInstance.on("mouseover", interactiveLayers, onHover);
    mapInstance.on("mouseleave", interactiveLayers, offHover);

    return () => {
      mapInstance.off("click", interactiveLayers, onClick);
      mapInstance.off("click", offFeatureClick);
      mapInstance.off("mouseover", interactiveLayers, onHover);
      mapInstance.off("mouseleave", interactiveLayers, offHover);
    };
  }, [mapInstance, onClick, offFeatureClick, onHover, offHover]);

  return null;
}
