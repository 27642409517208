import { recoilPersist } from "recoil-persist";

const { atom, selector } = require("recoil");

const { persistAtom } = recoilPersist({
  key: "recoil-persist",
  storage: localStorage,
});

export const ssrState = atom({
  key: "ssrState",
  default: false,
});

export const layerState = atom({
  key: "layerState",
  default: {
    ad: false,
    ssr: false,
    adsb: false,
    adsb_sites: false,
    adsb_by_site: false,
    adsb_sites_selected: false,
    vhf: false,
    vhf_sites: false,
    vhf_sites_selected: false,
    vhf_combined: false,
    navaid: false,
    navaid_coverage: false,
    navaid_combined: false,
    vnc: false,
    linz: false,
    satellite: false,
    fixes: false,
    routes: false,
    sectors: false,
    sector_labels: false,
  },
});

export const layerStateHandler = selector({
  key: "layerStateHandler",
  get: ({ get }) => {},
  set: ({ get, set }, newValue) => {
    // console.log(newValue);
    // console.log(get(layerState));
    var layers = { ...get(layerState) };
    layers[newValue] = !layers[newValue];
    set(layerState, layers);
  },
});
export const selectedAltitudeState = atom({
  key: "selectedAltitudeState",
  default: 5000,
});

export const symbolsLoadedState = atom({
  key: "symbolsLoadedState",
  default: true,
});

export const clickedFeatureState = atom({
  key: "clickedFeatureState",
  default: null,
});

export const navaidsSelectedState = atom({
  key: "navaidsSelectedState",
  default: [],
});

export const navaidTypeSelectedState = atom({
  key: "navaidTypeSelectedState",
  default: "",
});

export const navaidPanelOpenState = atom({
  key: "navaidPanelOpenState",
  default: false,
});

export const vhfPanelOpenState = atom({
  key: "vhfPanelOpenState",
  default: false,
});

export const vhfSelectedState = atom({
  key: "vhfSelectedState",
  default: [],
});

export const vhfTypeSelectedState = atom({
  key: "vhfSelectedState",
  default: "",
});

export const navaidTypeOpenState = atom({
  key: "navaidTypeOpenState",
  default: false,
});

export const navaidAccordionOpenState = atom({
  key: "navaidAccordionOpenState",
  default: false,
});

export const basemapModalOpenState = atom({
  key: "basemapModalOpenState",
  default: false,
});

export const adsbSelectedState = atom({
  key: "adsbSelectedState",
  default: [],
});

export const adsbPanelOpenState = atom({
  key: "adsbPanelOpenState",
  default: false,
});

export const mapControlsExpandedState = atom({
  key: "mapControlsExpandedState",
  default: true,
  effects_UNSTABLE: [persistAtom],
});

// Sets base map layer state
export const basemapState = atom({
  key: "basemapState",
  default: "standard",
  effects_UNSTABLE: [persistAtom],
});

export const navaidListState = atom({
  key: "navaidListState",
  default: [],
});

export const vhfListState = atom({
  key: "vhfListState",
  default: [],
});
