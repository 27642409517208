import React from "react";
import { Source, Layer } from "react-map-gl";
import { useRecoilValue } from "recoil";

import { layerState } from "../../../globalState";

export default function SatelliteLayer() {
  const layers = useRecoilValue(layerState);
  //   const times = useRecoilValue(selectedWxTileTimesState);

  // useEffect(() => {
  //     map.moveLayer('mapboxSatellite', 'country-label')
  //     if (layerSelection.met.satelliteCloudVisible) {
  //         map.moveLayer('mapboxSatellite', `satelliteCloudVisible${times[0]}`)
  //         map.moveLayer('waterMask', `satelliteCloudVisible${times[0]}`)
  //     } else if (layerSelection.met.satelliteCloudTops) {
  //         map.moveLayer('mapboxSatellite', `satelliteCloudTops${times[0]}`)
  //         map.moveLayer('waterMask', `satelliteCloudTops${times[0]}`)
  //     } else if (layerSelection.met.satelliteCloudInfrared) {
  //         map.moveLayer(
  //             'mapboxSatellite',
  //             `satelliteCloudInfrared${times[0]}`
  //         )
  //         map.moveLayer('waterMask', `satelliteCloudInfrared${times[0]}`)
  //     } else if (layerSelection.met.satelliteCloudInfraredEnhanced) {
  //         map.moveLayer(
  //             'mapboxSatellite',
  //             `satelliteCloudInfraredEnhanced${times[0]}`
  //         )
  //         map.moveLayer(
  //             'waterMask',
  //             `satelliteCloudInfraredEnhanced${times[0]}`
  //         )
  //     }
  // }, [layerSelection, map, times])

  //create conditional function that checks what layer is visible and moves the layer behind the first tile in the group
  if (layers.satellite) {
    return (
      <>
        <Source
          id="mapboxSatellite"
          type="raster"
          url="mapbox://mapbox.satellite"
          //   tileSize=256
        />
        <Layer
          id="mapboxSatellite"
          beforeId="country-label"
          type="raster"
          source="mapboxSatellite"
          //   before="tunnel-street-minor-low"
          paint={{
            "raster-brightness-max": 0.75,
            "raster-brightness-min": 0.25,
            "raster-contrast": 0.5,
            "raster-saturation": -0.33,
            "raster-opacity": 1,
            // "raster-fade-duration": 250,
          }}
        />
        {/* <Layer
          id="waterMask"
          type="fill"
          source="composite"
          sourceLayer="water"
          before="tunnel-street-minor-low"
          paint={{
            //  'fill-color': '#B4CEDB', // Default value
            "fill-color": "#BAD2DE", // changed from {default value}
            // 'fill-opacity': 0.9,
          }}
        />
        <Layer
          id="waterMaskOutline"
          type="line"
          source="composite"
          sourceLayer="water"
          before="tunnel-street-minor-low"
          paint={{
            "line-color": "#4b96c8", // "#068cdd",
            "line-blur": 0.5,
          }}
        /> */}
      </>
    );
  }
  return null;
}
