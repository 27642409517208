import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Slide,
  Box,
  Icon,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { isMobileOnly, isIOS } from "react-device-detect";
import {
  layerState,
  layerStateHandler,
  navaidsSelectedState,
  navaidPanelOpenState,
  navaidTypeSelectedState,
  navaidListState,
} from "../../globalState";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import MenuSwitch from "../components/MenuSwitch";
import { GoDotFill } from "react-icons/go";

export default function NavaidSwitches({ hasVOR, hasDME }) {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const [navaidList, setNavaidList] = useRecoilState(navaidListState);
  const [navaidsSelected, setNavaidsSelected] =
    useRecoilState(navaidsSelectedState);
  const [navaidPanelOpen] = useRecoilState(navaidPanelOpenState);
  const [navaidTypeSelected, setNavaidTypeSelected] = useRecoilState(
    navaidTypeSelectedState
  );
  const [expandedDropdowns, setExpandedDropdowns] = useState(-1);

  useEffect(() => {
    fetch(`${window.location.origin}/data/navaid_list`)
      .then((res) => res.json())
      .then((data) => setNavaidList(data));
  }, [setNavaidList]);

  useEffect(() => {
    if (navaidsSelected.length && !layers.navaid_coverage) {
      layerHandler("navaid_coverage");
      if (navaidTypeSelected !== "") {
        setNavaidTypeSelected("");
      }
    }
    if (!navaidsSelected.length && layers.navaid_coverage) {
      layerHandler("navaid_coverage");
    }
  }, [
    layers.navaid_coverage,
    navaidsSelected,
    navaidTypeSelected,
    layerHandler,
    setNavaidTypeSelected,
  ]);

  useEffect(() => {
    if (!navaidPanelOpen) {
      setExpandedDropdowns(-1);
    }
  }, [navaidPanelOpen]);

  const drawerBg = useColorModeValue("gray.200", "#1A202A");
  const drawerBgGradient = useColorModeValue("#d3dae3", "#111A23");
  const drawerBgGradientFaded = useColorModeValue("#E2E8F0", "#111A23");
  const drawerItemColor = useColorModeValue("gray.800", "gray.300");
  const drawerItemHover = useColorModeValue(
    "rgba(20, 50, 150, 0.05)",
    "rgba(255,255,255,0.05)"
  );
  const drawerItemColorSelected = useColorModeValue("gray.900", "white");
  const accordionButtonBgActive = useColorModeValue(
    "rgba(20, 50, 150, 0.04)",
    "rgba(255,255,255,0.04)"
  );
  const accordionButtonBgHover = useColorModeValue(
    "rgba(20, 50, 150, 0.07)",
    "rgba(255,255,255,0.07)"
  );
  const dividerColor = useColorModeValue(
    "rgba(20, 50, 150, 0.07)",
    "rgba(255,255,255,0.05)"
  );

  const scrollBarHide = useBreakpointValue({
    base: "scroll",
    md: "leave",
  });

  const slideTransition = useBreakpointValue({
    base: "bottom",
    md: "left",
  });
  const isIphone = isMobileOnly && isIOS;

  // useEffect(() => {
  //   console.log("navaidsSelected", navaidsSelected);
  // }, [navaidsSelected]);

  return (
    // option 1: accordian
    // <Flex width="180px">
    //   <Accordion
    //     defaultIndex={[0]}
    //     allowMultiple
    //     borderColor="#005bd500"
    //     width="180px"
    //   >
    //     <AccordionItem>
    //       <AccordionButton>
    //         <Grid templateColumns="repeat(2, 1fr)" gap="3" ml="-4">
    //           <Flex gap="2">
    //             <Text
    //               color="white"
    //               align="left"
    //               fontFamily="Montserrat, sans-serif"
    //               fontWeight="600"
    //               fontSize="12"
    //               width="78px"
    //             >
    //               NAVAIDS
    //             </Text>
    //             <AccordionIcon color="white" />
    //             <Spacer width="20px" />
    //           </Flex>
    //           <Switch
    //             colorScheme="navaid"
    //             isChecked={layers.navaid}
    //             onChange={() => layerHandler("navaid")}
    //             align="right"
    //           />
    //         </Grid>
    //       </AccordionButton>

    //       <AccordionPanel overflow="auto" mt="-2">
    //         {/* <Flex flexDirection="column" gap="0"> */}
    //         {navaidList.map((navaid) => {
    //           return (
    //             <Grid
    //               key={navaid.designator}
    //               templateColumns="repeat(2, 1fr)"
    //               gap="0"
    //               ml="-4"
    //               mt="1"
    //             >
    //               <Text
    //                 color="#b5b5b5"
    //                 align="left"
    //                 fontFamily="Montserrat, sans-serif"
    //                 fontWeight="600"
    //                 fontSize="10"
    //                 width="150px"
    //               >
    //                 {navaid.name} ({navaid.designator})
    //               </Text>
    //               <Switch
    //                 colorScheme="navaid"
    //                 size="sm"
    //                 align="right"
    //                 isChecked={navaidsSelected.includes(navaid.designator)}
    //                 onChange={() =>
    //                   setNavaidsSelected((prevState) => {
    //                     if (prevState.includes(navaid.designator)) {
    //                       return [...prevState].filter(
    //                         (item) => item !== navaid.designator
    //                       );
    //                     } else {
    //                       return [...prevState, navaid.designator];
    //                     }
    //                   })
    //                 }
    //               />
    //             </Grid>
    //           );
    //         })}
    //         {/* </Flex> */}
    //       </AccordionPanel>
    //     </AccordionItem>
    //   </Accordion>
    // </Flex>

    // option 2: panel/drawer thing
    <>
      <Slide
        in={navaidPanelOpen}
        direction={slideTransition}
        style={{ pointerEvents: "none" }}
        zIndex="10"
      >
        <Box
          height={{
            base: isIphone ? "calc(100vh - 90px)" : "calc(100vh - 75px)",
            md: "100vh",
          }}
          borderTopLeftRadius={{ base: "20px", md: "0px" }}
          borderTopRightRadius={{ base: "20px", md: "0px" }}
          borderBottomRightRadius={{ base: "0px", md: "0px" }}
          width="270px"
          color="white"
          bg={drawerBg}
          marginLeft={{ base: "15px", md: "55px" }}
          pb={{ base: isIphone ? "75px" : "60px", md: "0px" }}
          boxShadow="0px 0px 20px 0px rgba(0,0,0,0.3)"
          zIndex="100"
          pointerEvents="auto"
          overflow="hidden"
        >
          <Flex
            flexDirection="column"
            height="100%"
            width="100%"
            boxShadow={{
              base: "inset 0px -5px 15px -7px rgba(0, 0, 0, 0.15)",
              md: "inset 5px 0px 15px -7px rgba(0, 0, 0, 0.1)",
            }}
          >
            <OverlayScrollbarsComponent
              options={{
                scrollbars: {
                  visibility: "auto",
                  autoHideDelay: 800,
                  autoHide: scrollBarHide,
                  dragScrolling: true,
                  clickScrolling: true,
                  touchSupport: true,
                },
                overflow: {
                  x: "hidden",
                  y: "scroll",
                },
              }}
              style={{
                height: "100vh",
                width: "100%",
              }}
            >
              <Flex
                flexDirection="column"
                gap="0"
                width="100%"
                height="100%"
                pt="15px"
                pb="20px"
              >
                <Flex>
                  <Accordion
                    allowToggle
                    index={expandedDropdowns}
                    onChange={(expandedIndex) => {
                      setExpandedDropdowns(expandedIndex);
                    }}
                    borderColor={dividerColor}
                    width="100%"
                  >
                    <AccordionItem width="100%">
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton
                            width="100%"
                            height="45px"
                            opacity={isExpanded ? "1" : hasVOR ? "0.75" : "0.5"}
                            transition="opacity ease 200ms"
                            bg={isExpanded && accordionButtonBgActive}
                            _hover={{ bg: accordionButtonBgHover }}
                          >
                            <Text
                              color={
                                hasVOR
                                  ? "vor.600"
                                  : isExpanded
                                  ? drawerItemColorSelected
                                  : drawerItemColor
                              }
                              align="left"
                              fontFamily="Montserrat, sans-serif"
                              fontWeight={isExpanded ? "700" : "600"}
                              fontSize="12"
                              width="100%"
                            >
                              VOR
                            </Text>
                            <AccordionIcon
                              color={
                                hasVOR
                                  ? "vor.600"
                                  : isExpanded
                                  ? drawerItemColorSelected
                                  : drawerItemColor
                              }
                            />
                          </AccordionButton>

                          <AccordionPanel
                            p="0px"
                            backgroundImage={`linear-gradient(180deg, ${drawerBgGradient} 0%, ${drawerBgGradientFaded}00 8%)`}
                            pt="10px"
                          >
                            <Flex
                              width="100%"
                              height="100%"
                              minHeight="max-content"
                              flexDirection="column"
                              overflow="hidden"
                              backgroundImage={`linear-gradient(0deg, ${drawerBgGradient} 0%, ${drawerBgGradientFaded}00 6%)`}
                              pb="20px"
                            >
                              {navaidList
                                .filter((navaid) => navaid.type === "VOR")
                                .map((navaid, i) => {
                                  return (
                                    <Flex
                                      pl="20px"
                                      pr="15px"
                                      key={navaid.navid}
                                      width="100%"
                                      minHeight="30px"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      cursor="pointer"
                                      borderTop={i === 0 && "1px solid"}
                                      borderBottom="1px solid"
                                      borderColor={dividerColor}
                                      _hover={{ bg: drawerItemHover }}
                                      onClick={() =>
                                        setNavaidsSelected((prevState) => {
                                          if (
                                            prevState.includes(navaid.navid)
                                          ) {
                                            return [...prevState].filter(
                                              (item) => item !== navaid.navid
                                            );
                                          } else {
                                            return [...prevState, navaid.navid];
                                          }
                                        })
                                      }
                                    >
                                      <Icon
                                        as={GoDotFill}
                                        fontSize={
                                          navaidsSelected.includes(navaid.navid)
                                            ? "1.1rem"
                                            : "0rem"
                                        }
                                        transition="all ease 200ms"
                                        color="vor.500"
                                        pr={
                                          navaidsSelected.includes(navaid.navid)
                                            ? "5px"
                                            : "0px"
                                        }
                                      />
                                      <Text
                                        color={
                                          navaidsSelected.includes(navaid.navid)
                                            ? drawerItemColorSelected
                                            : drawerItemColor
                                        }
                                        transition="all ease 200ms"
                                        align="left"
                                        fontFamily="Montserrat, sans-serif"
                                        fontWeight={
                                          navaidsSelected.includes(navaid.navid)
                                            ? "500"
                                            : "400"
                                        }
                                        fontSize="0.75rem"
                                        width="100%"
                                        minWidth="180px"
                                      >
                                        {navaid.name} ({navaid.designator})
                                      </Text>
                                      <MenuSwitch
                                        activeColor={"vor.500"}
                                        switchActive={navaidsSelected.includes(
                                          navaid.navid
                                        )}
                                      />
                                    </Flex>
                                  );
                                })}
                            </Flex>
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                    <AccordionItem width="100%">
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton
                            width="100%"
                            height="45px"
                            opacity={isExpanded ? "1" : hasDME ? "0.75" : "0.5"}
                            transition="opacity ease 200ms"
                            bg={isExpanded && accordionButtonBgActive}
                            _hover={{ bg: accordionButtonBgHover }}
                          >
                            <Text
                              color={
                                hasDME
                                  ? "dme.600"
                                  : isExpanded
                                  ? drawerItemColorSelected
                                  : drawerItemColor
                              }
                              align="left"
                              fontFamily="Montserrat, sans-serif"
                              fontWeight={isExpanded ? "700" : "600"}
                              fontSize="12"
                              width="100%"
                            >
                              DME
                            </Text>
                            <AccordionIcon
                              color={
                                hasDME
                                  ? "dme.600"
                                  : isExpanded
                                  ? drawerItemColorSelected
                                  : drawerItemColor
                              }
                            />
                          </AccordionButton>
                          <AccordionPanel
                            p="0px"
                            backgroundImage={`linear-gradient(180deg, ${drawerBgGradient} 0%, ${drawerBgGradientFaded}00 8%)`}
                            pt="10px"
                          >
                            <Flex
                              width="100%"
                              height="100%"
                              minHeight="max-content"
                              flexDirection="column"
                              overflow="hidden"
                              backgroundImage={`linear-gradient(0deg, ${drawerBgGradient} 0%, ${drawerBgGradientFaded}00 6%)`}
                              pb="20px"
                            >
                              {navaidList
                                .filter((navaid) => navaid.type === "DME")
                                .map((navaid) => {
                                  return (
                                    <Flex
                                      pl="20px"
                                      pr="15px"
                                      key={navaid.navid}
                                      width="100%"
                                      minHeight="30px"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      cursor="pointer"
                                      borderBottom="1px solid"
                                      borderColor={dividerColor}
                                      _hover={{ bg: drawerItemHover }}
                                      onClick={() =>
                                        setNavaidsSelected((prevState) => {
                                          if (
                                            prevState.includes(navaid.navid)
                                          ) {
                                            return [...prevState].filter(
                                              (item) => item !== navaid.navid
                                            );
                                          } else {
                                            return [...prevState, navaid.navid];
                                          }
                                        })
                                      }
                                    >
                                      <Icon
                                        as={GoDotFill}
                                        fontSize={
                                          navaidsSelected.includes(navaid.navid)
                                            ? "1.1rem"
                                            : "0rem"
                                        }
                                        transition="all ease 200ms"
                                        color="dme.500"
                                        pr={
                                          navaidsSelected.includes(navaid.navid)
                                            ? "5px"
                                            : "0px"
                                        }
                                      />
                                      <Text
                                        color={
                                          navaidsSelected.includes(navaid.navid)
                                            ? drawerItemColorSelected
                                            : drawerItemColor
                                        }
                                        transition="all ease 200ms"
                                        align="left"
                                        fontFamily="Montserrat, sans-serif"
                                        fontWeight={
                                          navaidsSelected.includes(navaid.navid)
                                            ? "500"
                                            : "400"
                                        }
                                        fontSize="0.75rem"
                                        width="100%"
                                        minWidth="180px"
                                      >
                                        {navaid.name} ({navaid.designator})
                                      </Text>
                                      <MenuSwitch
                                        activeColor={"dme.500"}
                                        switchActive={navaidsSelected.includes(
                                          navaid.navid
                                        )}
                                      />
                                    </Flex>
                                  );
                                })}
                            </Flex>
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  </Accordion>

                  {/* <Button
                height="20px"
                ml="65px"
                fontSize="9"
                fontFamily="Montserrat, sans-serif"
                fontWeight="400"
                color="black"
                textDecoration="underline"
                variant="unstyled"
                onClick={() =>
                  setNavaidsSelected(
                    navaidList
                      .filter((navaid) => navaid.type === "VOR")
                      .map((navaid) => navaid.designator)
                  )
                }
              >
                all on
              </Button> */}
                </Flex>

                {/* <Button
              ml="140px"
              fontSize="10"
              fontFamily="Montserrat, sans-serif"
              fontWeight="600"
              color="black"
              textDecoration="underline"
              variant="unstyled"
              onClick={() => setNavaidsSelected([])}
            >
              Clear all
            </Button> */}
              </Flex>
            </OverlayScrollbarsComponent>
          </Flex>
        </Box>
      </Slide>
    </>
  );
}
