import React from "react";
import { Source, Layer } from "react-map-gl";
import { selectedAltitudeState, adsbSelectedState } from "../../globalState";
import { useRecoilValue } from "recoil";

export default function AdsbBySite() {
  const selectedAltitude = useRecoilValue(selectedAltitudeState);
  const adsbSelected = useRecoilValue(adsbSelectedState);
  const dataLayer = {
    id: "adsb_by_site",
    beforeId: "continent-label",
    type: "fill",
    layout: { "fill-sort-key": 10 },
    filter: [
      "all",
      ["==", "altitude", selectedAltitude],
      ["in", "designator", ...adsbSelected],
    ],
    paint: {
      "fill-color": "#1681e9",
      "fill-opacity": 0.6,
    },
  };

  return (
    <Source
      type="geojson"
      data={`${window.location.origin}/data/coverage/adsb_by_site`}
    >
      <Layer {...dataLayer} />
    </Source>
  );
}
