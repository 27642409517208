import React from "react";
import { useRecoilValue } from "recoil";
import AdsbCoverage from "./adsb_coverage";
import AdsbBySite from "./adsb_by_site";
import AdsbSites from "./adsb_sites";
import NavaidCoverage from "./navaid_coverage";
import RadioCoverage from "./radio_coverage";
import SsrCoverage from "./ssr_coverage";
import Aerodromes from "./aerodromes";
import Navaids from "./navaids";
import VhfSites from "./vhf_sites";
import RadioCoverageCombined from "./radio_coverage_combined";
import NavaidCoverageCombined from "./navaid_coverage_combined";
import Fixes from "./fixes";
import Routes from "./routes";
import { layerState } from "../../globalState";
import BaseLayers from "./baselayers";
import VhfSitesSelected from "./vhf_sites_selected";
import AdsbSitesSelected from "./adsb_sites_selected";
import AtcSectors from "./atc_sectors_zz";
import AtcSectors2 from "./atc_sectors";
import AtcSectorLabels from "./atc_sector_labels";

export default function Layers() {
  const layers = useRecoilValue(layerState);

  return (
    <>
      {layers.ssr && <SsrCoverage />}
      {layers.adsb && <AdsbCoverage />}
      {layers.adsb_by_site && <AdsbBySite />}
      {layers.adsb_sites && <AdsbSites />}
      {layers.adsb_sites_selected && <AdsbSitesSelected />}
      {layers.vhf && <RadioCoverage />}
      {layers.vhf_combined && <RadioCoverageCombined />}
      {layers.vhf_sites && <VhfSites />}
      {layers.vhf_sites_selected && <VhfSitesSelected />}
      {layers.navaid && <Navaids />}
      {layers.navaid_coverage && <NavaidCoverage />}
      {layers.navaid_combined && <NavaidCoverageCombined />}
      {layers.fixes && <Fixes />}
      {layers.routes && <Routes />}
      {layers.ad && <Aerodromes />}
      {layers.sectors && <AtcSectors2 />}
      {layers.sector_labels && <AtcSectorLabels />}
      <BaseLayers />

      {/* <Imagery /> */}
    </>
  );
}
