import React from "react";
import { Source, Layer } from "react-map-gl";
import { selectedAltitudeState, vhfSelectedState } from "../../globalState";
import { useRecoilValue } from "recoil";

export default function RadioCoverage() {
  const selectedAltitude = useRecoilValue(selectedAltitudeState);
  const vhfSelected = useRecoilValue(vhfSelectedState);
  const dataLayer = {
    id: "radio",
    beforeId: "continent-label",
    type: "fill",
    layout: { "fill-sort-key": 10 },
    filter: [
      "all",
      ["==", "altitude", selectedAltitude],
      ["in", "designator", ...vhfSelected],
    ],
    paint: {
      "fill-color": [
        "match",
        ["get", "type"],
        "Proposed",
        "#ff0000",
        "#E91E63",
      ],
      "fill-opacity": 0.5,
      // "fill-outline-color": "#000000",
    },
  };

  return (
    <Source
      type="geojson"
      data={`${window.location.origin}/data/coverage/radio`}
    >
      <Layer {...dataLayer} />
    </Source>
  );
}
