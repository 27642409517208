import React from "react";
import { Source, Layer } from "react-map-gl";

export default function Fixes() {
  const dataLayer = {
    id: "fixes",
    type: "symbol",
    layout: {
      "icon-image": "fix",
      "text-field": ["get", "fix"],
      "text-offset": [0, 1.5],
      "text-size": 11,
      "text-font": ["Montserrat SemiBold"],
      "symbol-sort-key": 1,
      "icon-size": [
        "interpolate",
        ["linear"],
        ["zoom"],
        4,
        0.3,
        6,
        0.3,
        8,
        0.3,
        10,
        0.4,
        16,
        0.5,
      ],
    },

    paint: {
      "text-color": "#000000",
      "text-halo-width": 1.5,
      "text-halo-color": "#ffffff",
    },
  };

  return (
    <Source type="geojson" data={`${window.location.origin}/data/fixes`}>
      <Layer {...dataLayer} />
    </Source>
  );
}
