import React from "react";
import { Source, Layer } from "react-map-gl";

export default function AtcSectorLabels() {
  const dataLayer = {
    id: "sector_labels",
    // beforeId: "continent-label",
    type: "symbol",
    layout: {
      "text-field": [
        "format",
        ["concat", ["get", "displayname"], " ", ["get", "type"], "\n"],
        {},
        ["get", "upper"],
        {},
        "\n———————\n",
        {
          "font-scale": 0.6,
          "text-font": ["literal", ["Roboto Black", "Montserrat SemiBold"]],
        },
        ["get", "lower"],
        {},
      ],
      "text-size": 10,
      "text-font": ["Montserrat SemiBold"],
    },

    paint: {
      "text-halo-width": 1,
      "text-halo-color": "#ffffff",
      "text-color": ["match", ["get", "type"], "TMA", "#008080", "#3A3B3C"],
    },
  };

  return (
    <Source
      type="geojson"
      data={`${window.location.origin}/data/sector_labels`}
    >
      <Layer {...dataLayer} />
    </Source>
  );
}
