import React from "react";
import { useRecoilValue } from "recoil";
import { Source, Layer } from "react-map-gl";
import { layerState } from "../../globalState";
export default function AtcSectors2() {
  const layers = useRecoilValue(layerState);

  return (
    <Source type="geojson" data={`${window.location.origin}/data/sectors`}>
      <Layer
        id="sectors"
        type="line"
        layout={{
          "line-sort-key": ["match", ["get", "type"], "TMA", 1, 2],
        }}
        paint={
          layers.satellite
            ? {
                "line-color": [
                  "match",
                  ["get", "type"],
                  "TMA",
                  "#18A7B5",
                  "#8f8f8f",
                ],
                "line-width": ["match", ["get", "type"], "TMA", 3, 1],
              }
            : layers.vnc
            ? {
                "line-color": [
                  "match",
                  ["get", "type"],
                  "TMA",
                  "#18A7B5",
                  "#000000",
                ],
                "line-width": ["match", ["get", "type"], "TMA", 3, 1],
              }
            : {
                "line-color": [
                  "match",
                  ["get", "type"],
                  "TMA",
                  "#18A7B5",
                  "#696969",
                ],
                "line-width": ["match", ["get", "type"], "TMA", 3, 1],
              }
        }
      />
    </Source>
  );
}
