import React from "react";

import SatelliteLayer from "./Satellite";
import VNCLayer from "./VNC";
import LinzTopoLayer from "./LinzTopo";

export default function BaseLayers() {
  return (
    <>
      <SatelliteLayer />
      <VNCLayer />
      <LinzTopoLayer />
    </>
  );
}
