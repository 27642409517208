import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  useColorModeValue,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  layerState,
  layerStateHandler,
  vhfSelectedState,
  vhfPanelOpenState,
  adsbSelectedState,
  adsbPanelOpenState,
  navaidPanelOpenState,
  navaidTypeOpenState,
  navaidAccordionOpenState,
  navaidTypeSelectedState,
} from "../../globalState";
import { GiRadarSweep } from "react-icons/gi";
import { VscRadioTower } from "react-icons/vsc";
import { MdGpsFixed } from "react-icons/md";
import { ReactComponent as AdsbSymbol } from "../symbols/adsb1.svg";
import { ReactComponent as NavaidSymbol } from "../symbols/vor-dme.svg";
import { ReactComponent as NzSymbol } from "../symbols/New_Zealand_Simplified.svg";

export default function SwitchesDesktop({ selectedNavaidTypes }) {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);

  const setVhfSelected = useSetRecoilState(vhfSelectedState);
  const setAdsbSelected = useSetRecoilState(adsbSelectedState);
  const [vhfPanelOpen, setVhfPanelOpen] = useRecoilState(vhfPanelOpenState);
  const [adsbPanelOpen, setAdsbPanelOpen] = useRecoilState(adsbPanelOpenState);
  const [navaidPanelOpen, setNavaidPanelOpen] =
    useRecoilState(navaidPanelOpenState);
  const [navaidTypeOpen, setNavaidTypeOpen] =
    useRecoilState(navaidTypeOpenState);
  const [navaidTypeSelected, setNavaidTypeSelected] = useRecoilState(
    navaidTypeSelectedState
  );
  const setNavaidAccordionOpen = useSetRecoilState(navaidAccordionOpenState);

  const [expandedDropdowns, setExpandedDropdowns] = useState(-1);

  const switchBg = useColorModeValue(
    "rgba(20, 50, 150, 0.04)",
    "rgba(255,255,255,0.04)"
  );
  const switchBgActive = useColorModeValue("gray.200", "#1A202A");
  const switchBgHover = useColorModeValue(
    "rgba(20, 50, 150, 0.07)",
    "rgba(255,255,255,0.07)"
  );
  const switchMenuBg = useColorModeValue("#f2f5fa", "#222937");
  const switchMenuBgShadow = useColorModeValue(
    "inset 0px 3px 10px -3px rgba(10, 20, 30, 0.35)",
    "inset 0px 3px 10px -3px rgba(10, 20, 30, 0.9)"
  );
  const buttonBgHover = useColorModeValue(
    "rgba(20, 50, 150, 0.07)",
    "rgba(255,255,255,0.07)"
  );
  const buttonColor = useColorModeValue("gray.900", "white");
  const buttonBorderColor = useColorModeValue("gray.900", "white");
  const buttonColorHover = useColorModeValue("gray.800", "white");
  const buttonColorOpacity = useColorModeValue("0.9", "0.5");
  const dividerColor = useColorModeValue(
    "rgba(20, 50, 150, 0.15)",
    "rgba(255,255,255,0.1)"
  );
  // close popout Site sub-menus if accordion closes
  useEffect(() => {
    if (expandedDropdowns !== 0) {
      setAdsbPanelOpen(false);
    }
    if (expandedDropdowns !== 1) {
      setVhfPanelOpen(false);
    }
    if (expandedDropdowns !== 2) {
      setNavaidPanelOpen(false);
      setNavaidTypeOpen(false);
      setNavaidAccordionOpen(false);
    }
  }, [
    expandedDropdowns,
    setAdsbPanelOpen,
    setVhfPanelOpen,
    setNavaidTypeOpen,
    setNavaidPanelOpen,
    setNavaidAccordionOpen,
  ]);

  useEffect(() => {
    // if individual sites are selected, then toggle off 'Show All'
    if (layers.adsb && layers.adsb_by_site) {
      layerHandler("adsb");
    }
    if (layers.vhf_combined && layers.vhf) {
      layerHandler("vhf_combined");
    }
    if (layers.navaid_combined && layers.navaid_coverage) {
      layerHandler("navaid_combined");
    }
  }, [layers, layerHandler, setNavaidTypeSelected]);

  // useEffect(() => {
  //   console.log("layers", layers);
  // }, [layers]);

  return (
    <Flex width="55px" zIndex="10" flexDirection="column">
      <Divider borderColor={dividerColor} />

      <Flex
        transition="all ease 200ms"
        height="80px"
        p="0px"
        alignItems="center"
        justifyContent="center"
        bg={layers.ssr ? switchBg : undefined}
        color={layers.ssr ? "ssr.500" : buttonColor}
        flexDirection="column"
        gap="2"
        width="55px"
        opacity={layers.ssr ? "1" : buttonColorOpacity}
        fontSize="10"
        fontWeight="400"
        fontFamily="Montserrat, sans-serif"
        onClick={() => layerHandler("ssr")}
        cursor="pointer"
        _hover={{
          bg: buttonBgHover,
          opacity: "1",
          color: layers.ssr ? "ssr.500" : buttonColorHover,
        }}
        _expanded={{
          bg: switchBg,
          opacity: "1",
          color: layers.ssr ? "ssr.500" : buttonColorHover,
          fontWeight: "600",
        }}
        _active={{ bg: switchBg, color: "ssr.500", fontWeight: "600" }}
      >
        <GiRadarSweep size="25" />
        <Text>MSSR</Text>
      </Flex>

      <Accordion
        allowToggle
        borderColor="#005bd500"
        width="55px"
        index={expandedDropdowns}
        onChange={(expandedIndex) => {
          setExpandedDropdowns(expandedIndex);
        }}
      >
        <Divider borderColor={dividerColor} />

        <AccordionItem mt="0px" border="none">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                transition="all ease 200ms"
                height="80px"
                p="0px"
                alignItems="center"
                justifyContent="center"
                color={
                  layers.adsb || layers.adsb_by_site ? "adsb.500" : buttonColor
                }
                opacity={
                  layers.adsb || layers.adsb_by_site ? "1" : buttonColorOpacity
                }
                bg={layers.adsb || layers.adsb_by_site ? switchBg : undefined}
                fontSize="10"
                fontWeight="400"
                fontFamily="Montserrat, sans-serif"
                onClick={() => {
                  if (!isExpanded) setAdsbPanelOpen(false);
                }}
                _hover={{
                  bg: buttonBgHover,
                  opacity: "1",
                  color:
                    layers.adsb || layers.adsb_by_site
                      ? "adsb.500"
                      : buttonColorHover,
                }}
                _expanded={{
                  bg: switchBg,
                  opacity: "1",
                  color:
                    layers.adsb || layers.adsb_by_site
                      ? "adsb.500"
                      : buttonColorHover,
                  fontWeight: "600",
                }}
              >
                <Flex flexDirection="column" alignItems="center" gap="2">
                  <AdsbSymbol height="25" width="auto" />
                  <Text>ADSB</Text>
                </Flex>
              </AccordionButton>
              <AccordionPanel
                bg={switchMenuBg}
                color={buttonColor}
                fontSize="10"
                fontWeight="400"
                fontFamily="Montserrat, sans-serif"
                width="100%"
                p="0px"
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  gap="0"
                  width="100%"
                  boxShadow={switchMenuBgShadow}
                >
                  <Flex
                    cursor="pointer"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="5px"
                    minHeight="80px"
                    width="100%"
                    opacity="0.8"
                    transition="all ease 200ms"
                    bg={layers.adsb ? switchBg : undefined}
                    color={layers.adsb ? "adsb.500" : undefined}
                    onClick={() => {
                      layerHandler("adsb");
                      if (layers.adsb_by_site) {
                        layerHandler("adsb_by_site");
                      }
                      setAdsbPanelOpen(false);
                      setAdsbSelected([]);
                    }}
                    _active={{
                      bg: switchBgActive,
                      color: layers.adsb ? "adsb.500" : buttonColorHover,

                      opacity: "1",
                    }}
                    _hover={{
                      bg: switchBgHover,
                      color: layers.adsb ? "adsb.500" : buttonColorHover,

                      opacity: "1",
                    }}
                    p="5px"
                  >
                    <Icon ml="10px" as={NzSymbol} height="30px" width="auto" />
                    <Text
                      width="100%"
                      textAlign="center"
                      lineHeight="1.2"
                      fontSize="0.5rem"
                    >
                      SHOW ALL
                    </Text>
                  </Flex>
                  <Divider borderColor={dividerColor} />

                  <Flex
                    cursor="pointer"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="7px"
                    minHeight="80px"
                    width="100%"
                    opacity={adsbPanelOpen ? "1" : "0.8"}
                    transition="all ease 200ms, padding ease-out 600ms"
                    bg={adsbPanelOpen ? switchBgActive : undefined}
                    color={layers.adsb_by_site ? "adsb.500" : buttonColor}
                    onClick={() => {
                      setAdsbPanelOpen((prevState) => !prevState);
                    }}
                    _active={{
                      bg: switchBgActive,
                      color: layers.adsb_by_site ? "adsb.500" : buttonColor,
                      opacity: "1",
                    }}
                    _hover={{
                      bg: adsbPanelOpen ? switchBgActive : switchBgHover,
                      color: layers.adsb_by_site ? "adsb.500" : buttonColor,
                      opacity: "1",
                    }}
                    p="5px"
                    pl={adsbPanelOpen ? "10px" : "5px"}
                    pb="10px"
                  >
                    <Box
                      opacity={adsbPanelOpen ? "1" : "0"}
                      transition="all ease 600ms, background ease 300ms"
                      height="80px"
                      width="2px"
                      bg={layers.adsb_by_site ? "adsb.500" : buttonBorderColor}
                      marginTop="5px"
                      left="0px"
                      position="absolute"
                      zIndex="1"
                    ></Box>
                    <Icon as={MdGpsFixed} fontSize="1.4rem" width="auto" />
                    <Text
                      width="100%"
                      textAlign="center"
                      lineHeight="1.2"
                      fontSize="0.55rem"
                      whiteSpace="pre"
                    >
                      BY SITE
                    </Text>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <Divider borderColor={dividerColor} />

        <AccordionItem mt="0px" border="none">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                transition="all ease 200ms"
                height="80px"
                p="0px"
                alignItems="center"
                justifyContent="center"
                color={
                  layers.vhf || layers.vhf_combined ? "vhf.500" : buttonColor
                }
                opacity={
                  layers.vhf || layers.vhf_combined ? "1" : buttonColorOpacity
                }
                bg={layers.vhf || layers.vhf_combined ? switchBg : undefined}
                fontSize="10"
                fontWeight="400"
                fontFamily="Montserrat, sans-serif"
                onClick={() => {
                  if (!isExpanded) {
                    setVhfPanelOpen(false);
                  }
                }}
                _hover={{
                  bg: buttonBgHover,
                  opacity: "1",
                  color:
                    layers.vhf || layers.vhf_combined
                      ? "vhf.500"
                      : buttonColorHover,
                }}
                _expanded={{
                  bg: switchBg,
                  opacity: "1",
                  color:
                    layers.vhf || layers.vhf_combined
                      ? "vhf.500"
                      : buttonColorHover,
                  fontWeight: "600",
                }}
              >
                <Flex flexDirection="column" alignItems="center" gap="1">
                  <VscRadioTower fontSize="1.7rem" />
                  <Text>VHF</Text>
                </Flex>
              </AccordionButton>
              <AccordionPanel
                bg={switchMenuBg}
                color={buttonColor}
                fontSize="10"
                fontWeight="400"
                fontFamily="Montserrat, sans-serif"
                width="100%"
                p="0px"
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  gap="0"
                  width="100%"
                  boxShadow={switchMenuBgShadow}
                >
                  <Flex
                    cursor="pointer"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="5px"
                    minHeight="80px"
                    width="100%"
                    opacity="0.8"
                    transition="all ease 200ms"
                    bg={layers.vhf_combined ? switchBg : undefined}
                    color={layers.vhf_combined ? "vhf.500" : undefined}
                    onClick={() => {
                      if (layers.vhf) {
                        layerHandler("vhf");
                      }
                      layerHandler("vhf_combined");
                      setVhfSelected([]);
                      setVhfPanelOpen(false);
                    }}
                    _active={{
                      bg: switchBgActive,
                      color: layers.vhf_combined ? "vhf.500" : buttonColor,

                      opacity: "1",
                    }}
                    _hover={{
                      bg: switchBgHover,
                      color: layers.vhf_combined ? "vhf.500" : buttonColor,

                      opacity: "1",
                    }}
                    p="5px"
                  >
                    <Icon ml="10px" as={NzSymbol} height="30px" width="auto" />
                    <Text
                      width="100%"
                      textAlign="center"
                      lineHeight="1.2"
                      fontSize="0.5rem"
                    >
                      SHOW ALL
                    </Text>
                  </Flex>
                  <Divider borderColor={dividerColor} />

                  <Flex
                    cursor="pointer"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="7px"
                    minHeight="80px"
                    width="100%"
                    opacity={vhfPanelOpen ? "1" : "0.8"}
                    transition="all ease 200ms, padding ease-out 600ms"
                    bg={vhfPanelOpen ? switchBgActive : undefined}
                    color={layers.vhf ? "vhf.500" : buttonColor}
                    onClick={() => {
                      setVhfPanelOpen((prevState) => !prevState);
                    }}
                    _active={{
                      bg: switchBgActive,
                      color: layers.vhf ? "vhf.500" : buttonColor,
                      opacity: "1",
                    }}
                    _hover={{
                      bg: vhfPanelOpen ? switchBgActive : switchBgHover,
                      color: layers.vhf ? "vhf.500" : buttonColor,
                      opacity: "1",
                    }}
                    p="5px"
                    pl={vhfPanelOpen ? "10px" : "5px"}
                    pb="10px"
                  >
                    <Box
                      opacity={vhfPanelOpen ? "1" : "0"}
                      transition="all ease 600ms, background ease 300ms"
                      height="80px"
                      width="2px"
                      bg={layers.vhf ? "vhf.500" : buttonBorderColor}
                      marginTop="5px"
                      left="0px"
                      position="absolute"
                      zIndex="1"
                    ></Box>
                    <Icon as={MdGpsFixed} fontSize="1.4rem" width="auto" />
                    <Text
                      width="100%"
                      textAlign="center"
                      lineHeight="1.2"
                      fontSize="0.55rem"
                    >
                      BY SITE
                    </Text>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <Divider borderColor={dividerColor} />

        <AccordionItem mt="0px" border="none">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                transition="all ease 200ms"
                height="80px"
                p="0px"
                alignItems="center"
                justifyContent="center"
                color={
                  layers.navaid_combined && navaidTypeSelected !== ""
                    ? navaidTypeSelected === "VOR"
                      ? "vor.600"
                      : "dme.600"
                    : layers.navaid_coverage
                    ? selectedNavaidTypes
                    : buttonColor
                }
                opacity={
                  (layers.navaid_combined && navaidTypeSelected !== "") ||
                  layers.navaid_coverage
                    ? "1"
                    : buttonColorOpacity
                }
                bg={
                  (layers.navaid_combined && navaidTypeSelected !== "") ||
                  layers.navaid_coverage
                    ? switchBg
                    : undefined
                }
                fontSize="10"
                fontWeight="400"
                fontFamily="Montserrat, sans-serif"
                onClick={() => {
                  if (!isExpanded) {
                    setNavaidPanelOpen(false);
                    setNavaidAccordionOpen(false);
                  }
                }}
                _hover={{
                  bg: buttonBgHover,
                  opacity: "1",
                  color:
                    layers.navaid_combined && navaidTypeSelected !== ""
                      ? navaidTypeSelected === "VOR"
                        ? "vor.600"
                        : "dme.600"
                      : layers.navaid_coverage
                      ? selectedNavaidTypes
                      : buttonColorHover,
                }}
                _expanded={{
                  bg: switchBg,
                  opacity: "1",
                  color:
                    layers.navaid_combined && navaidTypeSelected !== ""
                      ? navaidTypeSelected === "VOR"
                        ? "vor.600"
                        : "dme.600"
                      : layers.navaid_coverage
                      ? selectedNavaidTypes
                      : buttonColorHover,
                  fontWeight: "600",
                }}
              >
                <Flex flexDirection="column" alignItems="center" gap="1">
                  <NavaidSymbol height="28px" width="auto" />
                  <Text>NAVAID</Text>
                </Flex>
              </AccordionButton>
              <AccordionPanel
                bg={switchMenuBg}
                color={buttonColor}
                fontSize="10"
                fontWeight="400"
                fontFamily="Montserrat, sans-serif"
                width="100%"
                p="0px"
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  gap="0"
                  width="100%"
                  boxShadow={switchMenuBgShadow}
                >
                  <Flex
                    cursor="pointer"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="5px"
                    minHeight="80px"
                    width="100%"
                    opacity={navaidTypeOpen ? "1" : "0.8"}
                    transition="all ease 200ms, padding ease-out 600ms"
                    bg={navaidTypeOpen ? switchBgActive : undefined}
                    color={
                      layers.navaid_combined && navaidTypeSelected !== ""
                        ? navaidTypeSelected === "VOR"
                          ? "vor.600"
                          : "dme.600"
                        : undefined
                    }
                    onClick={() => {
                      setNavaidTypeOpen((prevState) => !prevState);
                      if (navaidPanelOpen) {
                        setNavaidPanelOpen(false);
                      }
                    }}
                    _active={{
                      bg: switchBgActive,
                      color:
                        layers.navaid_combined && navaidTypeSelected !== ""
                          ? navaidTypeSelected === "VOR"
                            ? "vor.600"
                            : "dme.600"
                          : buttonColor,

                      opacity: "1",
                    }}
                    _hover={{
                      bg: switchBgHover,
                      color:
                        layers.navaid_combined && navaidTypeSelected !== ""
                          ? navaidTypeSelected === "VOR"
                            ? "vor.600"
                            : "dme.600"
                          : buttonColor,

                      opacity: "1",
                    }}
                    p="5px"
                    pl={navaidTypeOpen ? "7px" : "5px"}
                  >
                    <Box
                      opacity={navaidTypeOpen ? "1" : "0"}
                      transition="all ease 600ms, background ease 300ms"
                      height="80px"
                      width="2px"
                      bg={
                        layers.navaid_combined && navaidTypeSelected !== ""
                          ? navaidTypeSelected === "VOR"
                            ? "vor.600"
                            : "dme.600"
                          : buttonBorderColor
                      }
                      marginTop="0px"
                      left="0px"
                      position="absolute"
                      zIndex="1"
                    ></Box>
                    <Icon ml="10px" as={NzSymbol} height="30px" width="auto" />
                    <Text
                      width="100%"
                      textAlign="center"
                      lineHeight="1.2"
                      fontSize="0.5rem"
                      whiteSpace="pre"
                    >
                      SHOW ALL
                    </Text>
                  </Flex>
                  <Divider borderColor={dividerColor} />

                  <Flex
                    cursor="pointer"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="7px"
                    minHeight="80px"
                    width="100%"
                    opacity={navaidPanelOpen ? "1" : "0.8"}
                    transition="all ease 200ms, padding ease-out 600ms"
                    bg={navaidPanelOpen ? switchBgActive : undefined}
                    color={
                      layers.navaid_coverage ? selectedNavaidTypes : buttonColor
                    }
                    onClick={() => {
                      setNavaidPanelOpen((prevState) => !prevState);
                      setNavaidTypeOpen(false);
                    }}
                    _active={{
                      bg: switchBgActive,
                      color: layers.navaid_coverage
                        ? selectedNavaidTypes
                        : buttonColor,
                      opacity: "1",
                    }}
                    _hover={{
                      bg: navaidPanelOpen ? switchBgActive : switchBgHover,
                      color: layers.navaid_coverage
                        ? selectedNavaidTypes
                        : buttonColor,
                      opacity: "1",
                    }}
                    p="5px"
                    pl={navaidPanelOpen ? "10px" : "5px"}
                    pb="10px"
                  >
                    <Box
                      opacity={navaidPanelOpen ? "1" : "0"}
                      transition="all ease 600ms, background ease 300ms"
                      height="80px"
                      width="2px"
                      bg={
                        layers.navaid_coverage
                          ? selectedNavaidTypes
                          : buttonBorderColor
                      }
                      marginTop="5px"
                      left="0px"
                      position="absolute"
                      zIndex="1"
                    ></Box>
                    <Icon as={MdGpsFixed} fontSize="1.4rem" width="auto" />
                    <Text
                      width="100%"
                      textAlign="center"
                      lineHeight="1.2"
                      fontSize="0.55rem"
                    >
                      BY SITE
                    </Text>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <Divider borderColor={dividerColor} />
      </Accordion>
    </Flex>
  );
}
