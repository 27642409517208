import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Slide,
  Box,
  Icon,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { isMobileOnly, isIOS } from "react-device-detect";
import {
  layerState,
  layerStateHandler,
  vhfListState,
  vhfSelectedState,
  vhfPanelOpenState,
} from "../../globalState";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import MenuSwitch from "../components/MenuSwitch";
import { GoDotFill } from "react-icons/go";

export default function VhfSwitches({ vhfHasCurrent, vhfHasProposed }) {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const [vhfList, setVhfList] = useRecoilState(vhfListState);
  const [vhfSelected, setVhfSelected] = useRecoilState(vhfSelectedState);
  const [vhfPanelOpen] = useRecoilState(vhfPanelOpenState);
  const [expandedDropdowns, setExpandedDropdowns] = useState(1);

  const accordionButtonBgActive = useColorModeValue(
    "rgba(20, 50, 150, 0.04)",
    "rgba(255,255,255,0.04)"
  );
  const accordionButtonBgHover = useColorModeValue(
    "rgba(20, 50, 150, 0.07)",
    "rgba(255,255,255,0.07)"
  );
  const drawerBgGradient = useColorModeValue("#d3dae3", "#111A23");
  const drawerBgGradientFaded = useColorModeValue("#E2E8F0", "#111A23");

  useEffect(() => {
    fetch(`${window.location.origin}/data/vhf_list`)
      .then((res) => res.json())
      .then((data) => setVhfList(data));
  }, [setVhfList]);

  useEffect(() => {
    if (vhfSelected.length && !layers.vhf) {
      layerHandler("vhf");
    }
    if (!vhfSelected.length && layers.vhf) {
      layerHandler("vhf");
    }
  }, [vhfSelected, layers.vhf, layerHandler]);

  const drawerBg = useColorModeValue("gray.200", "#1A202A");
  const drawerItemColor = useColorModeValue("gray.800", "gray.300");
  const drawerItemHover = useColorModeValue(
    "rgba(20, 50, 150, 0.05)",
    "rgba(255,255,255,0.05)"
  );
  const drawerItemColorSelected = useColorModeValue("gray.900", "white");
  const dividerColor = useColorModeValue(
    "rgba(20, 50, 150, 0.07)",
    "rgba(255,255,255,0.05)"
  );

  const scrollBarHide = useBreakpointValue({
    base: "scroll",
    md: "leave",
  });

  const slideTransition = useBreakpointValue({
    base: "bottom",
    md: "left",
  });
  const isIphone = isMobileOnly && isIOS;

  // useEffect(() => {
  //   console.log("vhfSelected", vhfSelected);
  // }, [vhfSelected]);

  return (
    <Slide
      in={vhfPanelOpen}
      direction={slideTransition}
      style={{ pointerEvents: "none" }}
      zIndex="10"
    >
      <Box
        height={{
          base: isIphone ? "calc(100vh - 90px)" : "calc(100vh - 75px)",
          md: "100vh",
        }}
        borderTopLeftRadius={{ base: "20px", md: "0px" }}
        borderTopRightRadius={{ base: "20px", md: "0px" }}
        borderBottomRightRadius={{ base: "0px", md: "0px" }}
        width="270px"
        color="white"
        bg={drawerBg}
        marginLeft={{ base: "15px", md: "55px" }}
        pb={{ base: isIphone ? "75px" : "60px", md: "0px" }}
        boxShadow="0px 0px 20px 0px rgba(0,0,0,0.3)"
        zIndex="100"
        pointerEvents="auto"
        overflow="hidden"
      >
        <Flex
          flexDirection="column"
          height="100%"
          width="100%"
          boxShadow={{
            base: "inset 0px -5px 15px -7px rgba(0, 0, 0, 0.15)",
            md: "inset 5px 0px 15px -7px rgba(0, 0, 0, 0.1)",
          }}
        >
          <OverlayScrollbarsComponent
            options={{
              scrollbars: {
                visibility: "auto",
                autoHide: scrollBarHide,
                autoHideDelay: 800,
                dragScrolling: true,
                clickScrolling: true,
                touchSupport: true,
              },
              overflow: {
                x: "hidden",
                y: "scroll",
              },
            }}
            style={{
              height: "100vh",
              width: "100%",
            }}
          >
            <Flex
              flexDirection="column"
              gap="0"
              width="100%"
              height="100%"
              pt="5px"
              pb="20px"
            >
              {vhfList && vhfList.length && (
                <Accordion
                  allowToggle
                  index={expandedDropdowns}
                  onChange={(expandedIndex) => {
                    setExpandedDropdowns(expandedIndex);
                  }}
                  borderColor={dividerColor}
                  width="100%"
                >
                  <AccordionItem width="100%">
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          width="100%"
                          height="45px"
                          opacity={
                            isExpanded ? "1" : vhfHasProposed ? "0.75" : "0.5"
                          }
                          transition="opacity ease 200ms"
                          bg={isExpanded && accordionButtonBgActive}
                          _hover={{ bg: accordionButtonBgHover }}
                        >
                          <Text
                            color={
                              vhfHasProposed
                                ? "proposedVhf.500"
                                : isExpanded
                                ? drawerItemColorSelected
                                : drawerItemColor
                            }
                            align="left"
                            fontFamily="Montserrat, sans-serif"
                            fontWeight={isExpanded ? "700" : "600"}
                            fontSize="12"
                            width="100%"
                          >
                            PROPOSED
                          </Text>
                          <AccordionIcon
                            color={
                              vhfHasProposed
                                ? "proposedVhf.500"
                                : isExpanded
                                ? drawerItemColorSelected
                                : drawerItemColor
                            }
                          />
                        </AccordionButton>

                        <AccordionPanel
                          p="0px"
                          backgroundImage={`linear-gradient(180deg, ${drawerBgGradient} 0%, ${drawerBgGradientFaded}00 50%)`}
                          pt="10px"
                        >
                          <Flex
                            width="100%"
                            height="100%"
                            minHeight="max-content"
                            flexDirection="column"
                            overflow="hidden"
                            backgroundImage={`linear-gradient(0deg, ${drawerBgGradient} 0%, ${drawerBgGradientFaded}00 50%)`}
                            pb="20px"
                          >
                            {vhfList
                              .filter((vhf) => vhf.type === "Proposed")
                              .map((vhf) => {
                                return (
                                  <Flex
                                    pl="20px"
                                    pr="15px"
                                    key={vhf.designator}
                                    width="100%"
                                    minHeight="30px"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    cursor="pointer"
                                    borderBottom="1px solid"
                                    borderColor={dividerColor}
                                    _hover={{ bg: drawerItemHover }}
                                    onClick={() =>
                                      setVhfSelected((prevState) => {
                                        if (
                                          prevState.includes(vhf.designator)
                                        ) {
                                          return [...prevState].filter(
                                            (item) => item !== vhf.designator
                                          );
                                        } else {
                                          return [...prevState, vhf.designator];
                                        }
                                      })
                                    }
                                  >
                                    <Icon
                                      as={GoDotFill}
                                      fontSize={
                                        vhfSelected.includes(vhf.designator)
                                          ? "1.1rem"
                                          : "0rem"
                                      }
                                      transition="all ease 200ms"
                                      color="proposedVhf.500"
                                      pr={
                                        vhfSelected.includes(vhf.designator)
                                          ? "5px"
                                          : "0px"
                                      }
                                    />
                                    <Text
                                      color={
                                        vhfSelected.includes(vhf.designator)
                                          ? drawerItemColorSelected
                                          : drawerItemColor
                                      }
                                      transition="all ease 200ms"
                                      align="left"
                                      fontFamily="Montserrat, sans-serif"
                                      fontWeight={
                                        vhfSelected.includes(vhf.designator)
                                          ? "500"
                                          : "400"
                                      }
                                      fontSize="0.75rem"
                                      width="100%"
                                      minWidth="180px"
                                    >
                                      {vhf.name} ({vhf.designator})
                                    </Text>
                                    <MenuSwitch
                                      activeColor={"proposedVhf.500"}
                                      switchActive={vhfSelected.includes(
                                        vhf.designator
                                      )}
                                    />
                                  </Flex>
                                );
                              })}
                          </Flex>
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                  <AccordionItem width="100%">
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          width="100%"
                          height="45px"
                          opacity={
                            isExpanded ? "1" : vhfHasCurrent ? "0.75" : "0.5"
                          }
                          transition="opacity ease 200ms"
                          bg={isExpanded && accordionButtonBgActive}
                          _hover={{ bg: accordionButtonBgHover }}
                        >
                          <Text
                            color={
                              vhfHasCurrent
                                ? "vhf.500"
                                : isExpanded
                                ? drawerItemColorSelected
                                : drawerItemColor
                            }
                            align="left"
                            fontFamily="Montserrat, sans-serif"
                            fontWeight={isExpanded ? "700" : "600"}
                            fontSize="12"
                            width="100%"
                          >
                            CURRENT
                          </Text>
                          <AccordionIcon
                            color={
                              vhfHasCurrent
                                ? "vhf.500"
                                : isExpanded
                                ? drawerItemColorSelected
                                : drawerItemColor
                            }
                          />
                        </AccordionButton>
                        <AccordionPanel
                          p="0px"
                          backgroundImage={`linear-gradient(180deg, ${drawerBgGradient} 0%, ${drawerBgGradientFaded}00 8%)`}
                          pt="10px"
                        >
                          <Flex
                            width="100%"
                            height="100%"
                            minHeight="max-content"
                            flexDirection="column"
                            overflow="hidden"
                            backgroundImage={`linear-gradient(0deg, ${drawerBgGradient} 0%, ${drawerBgGradientFaded}00 6%)`}
                            pb="20px"
                          >
                            {vhfList
                              .filter((vhf) => vhf.type === "Current")
                              .map((vhf) => {
                                return (
                                  <Flex
                                    pl="20px"
                                    pr="15px"
                                    key={vhf.designator}
                                    width="100%"
                                    minHeight="30px"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    cursor="pointer"
                                    borderBottom="1px solid"
                                    borderColor={dividerColor}
                                    _hover={{ bg: drawerItemHover }}
                                    onClick={() =>
                                      setVhfSelected((prevState) => {
                                        if (
                                          prevState.includes(vhf.designator)
                                        ) {
                                          return [...prevState].filter(
                                            (item) => item !== vhf.designator
                                          );
                                        } else {
                                          return [...prevState, vhf.designator];
                                        }
                                      })
                                    }
                                  >
                                    <Icon
                                      as={GoDotFill}
                                      fontSize={
                                        vhfSelected.includes(vhf.designator)
                                          ? "1.1rem"
                                          : "0rem"
                                      }
                                      transition="all ease 200ms"
                                      color="vhf.500"
                                      pr={
                                        vhfSelected.includes(vhf.designator)
                                          ? "5px"
                                          : "0px"
                                      }
                                    />
                                    <Text
                                      color={
                                        vhfSelected.includes(vhf.designator)
                                          ? drawerItemColorSelected
                                          : drawerItemColor
                                      }
                                      transition="all ease 200ms"
                                      align="left"
                                      fontFamily="Montserrat, sans-serif"
                                      fontWeight={
                                        vhfSelected.includes(vhf.designator)
                                          ? "500"
                                          : "400"
                                      }
                                      fontSize="0.75rem"
                                      width="100%"
                                      minWidth="180px"
                                    >
                                      {vhf.name} ({vhf.designator})
                                    </Text>
                                    <MenuSwitch
                                      activeColor={"vhf.500"}
                                      switchActive={vhfSelected.includes(
                                        vhf.designator
                                      )}
                                    />
                                  </Flex>
                                );
                              })}
                          </Flex>
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              )}
            </Flex>
          </OverlayScrollbarsComponent>
        </Flex>
      </Box>
    </Slide>
  );
}
