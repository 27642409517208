import React from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  Flex,
  SlideFade,
  VStack,
  Tooltip,
  Text,
  Icon,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { isDesktop } from "react-device-detect";
import { ReactComponent as AerodromeSymbol } from "../symbols/aerodrome.svg";
import { ReactComponent as FixesSymbol } from "../symbols/waypoint-icao.svg";
import { ReactComponent as AdsbSymbol } from "../symbols/adsb1.svg";
import { ReactComponent as NavaidSymbol } from "../symbols/vor-dme.svg";
import { ReactComponent as SectorSymbol } from "../symbols/airspace.svg";
import { VscRadioTower } from "react-icons/vsc";
import { IoClose } from "react-icons/io5";
import { BsUiRadiosGrid } from "react-icons/bs";
import { layerState, layerStateHandler } from "../../globalState";

export function AerodromeToggle() {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const buttonColor = useColorModeValue("#FFFFFF", "#324051");
  const buttonBg = useColorModeValue("#FFFFFF30", "#FFFFFF30");
  const buttonBorderColor = useColorModeValue("#FFFFFF", "gray.700");
  const buttonBgSelected = useColorModeValue("#FFFFFFbb", "#324051bb");
  const buttonBgHover = useColorModeValue("#FFFFFF50", "#FFFFFF50");
  const buttonBgHoverSelected = useColorModeValue("#FFFFFFdd", "#324051dd");

  return (
    <>
      <Tooltip
        label="Aerodromes"
        color="white"
        hasArrow
        borderRadius="7px"
        height="26px"
        fontSize="0.7rem"
        placement="left"
        pt="4px"
        m="5px"
        bg="gray.600"
      >
        <Flex
          height="30px"
          minHeight="30px"
          width="30px"
          minWidth="30px"
          borderRadius="10px"
          border={layers.ad ? "2px solid" : undefined}
          borderColor={layers.ad ? buttonBorderColor : "transparent"}
          bg={layers.ad ? buttonBgSelected : buttonBg}
          color={layers.ad ? "ifr.100" : buttonColor}
          transition="all ease 200ms"
          alignItems="center"
          justifyContent="center"
          _hover={{
            bg: layers.ad ? buttonBgHoverSelected : buttonBgHover,
            cursor: "pointer",
          }}
          onClick={() => {
            layerHandler("ad");
          }}
        >
          <Icon as={AerodromeSymbol} height="24px" width="auto" />
        </Flex>
      </Tooltip>
    </>
  );
}

export function AdsbToggle({ show }) {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const buttonColor = useColorModeValue("#FFFFFF", "#324051");
  const buttonBg = useColorModeValue("#FFFFFF30", "#FFFFFF30");
  const buttonBorderColor = useColorModeValue("#FFFFFF", "gray.700");
  const buttonBgSelected = useColorModeValue("#FFFFFFbb", "#324051bb");
  const buttonBgHover = useColorModeValue("#FFFFFF50", "#FFFFFF50");
  const buttonBgHoverSelected = useColorModeValue("#FFFFFFdd", "#324051dd");

  return (
    <>
      <Tooltip
        display={show && "none"}
        label="ADSB sites"
        color="white"
        hasArrow
        borderRadius="7px"
        height="26px"
        fontSize="0.7rem"
        placement="left"
        pt="4px"
        m="5px"
        bg="gray.600"
      >
        <Flex
          height="30px"
          minHeight="30px"
          width="30px"
          minWidth="30px"
          borderRadius="10px"
          border={layers.adsb_sites ? "2px solid" : undefined}
          borderColor={layers.adsb_sites ? buttonBorderColor : "transparent"}
          bg={layers.adsb_sites ? buttonBgSelected : buttonBg}
          color={layers.adsb_sites ? "adsb.500" : show ? "white" : buttonColor}
          transition="all ease 200ms"
          alignItems="center"
          justifyContent="center"
          _hover={{
            bg: layers.adsb_sites ? buttonBgHoverSelected : buttonBgHover,
            cursor: "pointer",
          }}
          onClick={() => {
            layerHandler("adsb_sites");
            layerHandler("adsb_sites_selected");
          }}
        >
          <Icon as={AdsbSymbol} height="18px" width="auto" />
        </Flex>
      </Tooltip>
    </>
  );
}

export function VhfToggle({ show }) {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const buttonColor = useColorModeValue("#FFFFFF", "#324051");
  const buttonBg = useColorModeValue("#FFFFFF30", "#FFFFFF30");
  const buttonBorderColor = useColorModeValue("#FFFFFF", "gray.700");
  const buttonBgSelected = useColorModeValue("#FFFFFFbb", "#324051bb");
  const buttonBgHover = useColorModeValue("#FFFFFF50", "#FFFFFF50");
  const buttonBgHoverSelected = useColorModeValue("#FFFFFFdd", "#324051dd");

  return (
    <>
      <Tooltip
        display={show && "none"}
        label="VHF sites"
        color="white"
        hasArrow
        borderRadius="7px"
        height="26px"
        fontSize="0.7rem"
        placement="left"
        pt="4px"
        m="5px"
        bg="gray.600"
      >
        <Flex
          height="30px"
          minHeight="30px"
          width="30px"
          minWidth="30px"
          borderRadius="10px"
          border={layers.vhf_sites ? "2px solid" : undefined}
          borderColor={layers.vhf_sites ? buttonBorderColor : "transparent"}
          bg={layers.vhf_sites ? buttonBgSelected : buttonBg}
          color={layers.vhf_sites ? "vhf.500" : show ? "white" : buttonColor}
          transition="all ease 200ms"
          alignItems="center"
          justifyContent="center"
          _hover={{
            bg: layers.vhf_sites ? buttonBgHoverSelected : buttonBgHover,
            cursor: "pointer",
          }}
          onClick={() => {
            layerHandler("vhf_sites");
            layerHandler("vhf_sites_selected");
          }}
        >
          <Box pr="1px">
            <VscRadioTower fontSize="1.2rem" />
          </Box>
        </Flex>
      </Tooltip>
    </>
  );
}

export function NavaidToggle({ show }) {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const buttonColor = useColorModeValue("#FFFFFF", "#324051");
  const buttonBg = useColorModeValue("#FFFFFF30", "#FFFFFF30");
  const buttonBorderColor = useColorModeValue("#FFFFFF", "gray.700");
  const buttonBgSelected = useColorModeValue("#FFFFFFbb", "#324051bb");
  const buttonBgHover = useColorModeValue("#FFFFFF50", "#FFFFFF50");
  const buttonBgHoverSelected = useColorModeValue("#FFFFFFdd", "#324051dd");

  return (
    <>
      <Tooltip
        display={show && "none"}
        label="NAVAID sites"
        color="white"
        hasArrow
        borderRadius="7px"
        height="26px"
        fontSize="0.7rem"
        placement="left"
        pt="4px"
        m="5px"
        bg="gray.600"
      >
        <Flex
          height="30px"
          minHeight="30px"
          width="30px"
          minWidth="30px"
          borderRadius="10px"
          border={layers.navaid ? "2px solid" : undefined}
          borderColor={layers.navaid ? buttonBorderColor : "transparent"}
          bg={layers.navaid ? buttonBgSelected : buttonBg}
          color={layers.navaid ? "vor.500" : show ? "white" : buttonColor}
          transition="all ease 200ms"
          alignItems="center"
          justifyContent="center"
          _hover={{
            bg: layers.navaid ? buttonBgHoverSelected : buttonBgHover,
            cursor: "pointer",
          }}
          onClick={() => {
            layerHandler("navaid");
          }}
        >
          <Icon as={NavaidSymbol} height="20px" width="auto" />
        </Flex>
      </Tooltip>
    </>
  );
}

export function FixesToggle({ show }) {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const buttonColor = useColorModeValue("#FFFFFF", "#324051");
  const buttonBg = useColorModeValue("#FFFFFF30", "#FFFFFF30");
  const buttonBorderColor = useColorModeValue("#FFFFFF", "gray.700");
  const buttonBgSelected = useColorModeValue("#FFFFFFbb", "#324051bb");
  const buttonBgHover = useColorModeValue("#FFFFFF50", "#FFFFFF50");
  const buttonBgHoverSelected = useColorModeValue("#FFFFFFdd", "#324051dd");

  return (
    <>
      <Tooltip
        display={show && "none"}
        label="Routes & Fixes"
        color="white"
        hasArrow
        borderRadius="7px"
        height="26px"
        fontSize="0.7rem"
        placement="left"
        pt="4px"
        m="5px"
        bg="gray.600"
      >
        <Flex
          height="30px"
          minHeight="30px"
          width="30px"
          minWidth="30px"
          borderRadius="10px"
          border={layers.fixes ? "2px solid" : undefined}
          borderColor={layers.fixes ? buttonBorderColor : "transparent"}
          bg={layers.fixes ? buttonBgSelected : buttonBg}
          color={layers.fixes ? "gray.900" : show ? "white" : buttonColor}
          transition="all ease 200ms"
          alignItems="center"
          justifyContent="center"
          _hover={{
            bg: layers.fixes ? buttonBgHoverSelected : buttonBgHover,
            cursor: "pointer",
          }}
          onClick={() => {
            layerHandler("fixes");
            layerHandler("routes");
          }}
          overflow="hidden"
        >
          <Box pb="1px">
            <FixesSymbol width="30px" height="auto" />
          </Box>
        </Flex>
      </Tooltip>
    </>
  );
}

export function SectorToggle({ show }) {
  const layers = useRecoilValue(layerState);
  const layerHandler = useSetRecoilState(layerStateHandler);
  const buttonColor = useColorModeValue("#FFFFFF", "#324051");
  const buttonBg = useColorModeValue("#FFFFFF30", "#FFFFFF30");
  const buttonBorderColor = useColorModeValue("#FFFFFF", "gray.700");
  const buttonBgSelected = useColorModeValue("#FFFFFFbb", "#324051bb");
  const buttonBgHover = useColorModeValue("#FFFFFF50", "#FFFFFF50");
  const buttonBgHoverSelected = useColorModeValue("#FFFFFFdd", "#324051dd");

  return (
    <>
      <Tooltip
        display={show && "none"}
        label="ATC Sectors"
        color="white"
        hasArrow
        borderRadius="7px"
        height="26px"
        fontSize="0.7rem"
        placement="left"
        pt="4px"
        m="5px"
        bg="gray.600"
      >
        <Flex
          height="30px"
          minHeight="30px"
          width="30px"
          minWidth="30px"
          borderRadius="10px"
          border={layers.sectors ? "2px solid" : undefined}
          borderColor={layers.sectors ? buttonBorderColor : "transparent"}
          bg={layers.sectors ? buttonBgSelected : buttonBg}
          color={layers.sectors ? "cyan.700" : show ? "white" : buttonColor}
          transition="all ease 200ms"
          alignItems="center"
          justifyContent="center"
          _hover={{
            bg: layers.sectors ? buttonBgHoverSelected : buttonBgHover,
            cursor: "pointer",
          }}
          onClick={() => {
            layerHandler("sectors");
            layerHandler("sector_labels");
          }}
          overflow="hidden"
        >
          <Box pb="1px">
            <SectorSymbol width="30px" height="auto" />
          </Box>
        </Flex>
      </Tooltip>
    </>
  );
}

export default function LayerSwitcher(props) {
  const { show, setShow } = props;

  const buttonColor = useColorModeValue("#FFFFFF", "#324051");
  const buttonBg = useColorModeValue("#FFFFFF30", "#FFFFFF30");
  const buttonBgHover = useColorModeValue("#FFFFFF50", "#FFFFFF50");

  return (
    <>
      <Tooltip
        display={isDesktop ? "flex" : "none"}
        isDisabled={isDesktop ? false : true}
        label="Select Map Symbols"
        visibility={!show ? "visible" : "hidden"}
        hasArrow
        borderRadius="7px"
        height="26px"
        fontSize="0.7rem"
        alignItems="center"
        placement="left"
        m="5px"
        // color="light.100"
        bg="gray.600"
      >
        <Flex
          height="30px"
          minHeight="30px"
          width="30px"
          minWidth="30px"
          borderRadius="10px"
          bg={show ? "transparent" : buttonBg}
          color={show ? "white" : buttonColor}
          transition="all ease 200ms"
          alignItems="center"
          justifyContent="center"
          _hover={{
            bg: show ? buttonBg : buttonBgHover,
            cursor: "pointer",
          }}
          onClick={() => {
            setShow((prevState) => !prevState);
          }}
          overflow="hidden"
        >
          {show ? (
            <IoClose fontSize="1.6rem" />
          ) : (
            <BsUiRadiosGrid fontSize="1.3rem" />
          )}
        </Flex>
      </Tooltip>
      <SlideFade
        unmountOnExit
        offsetY="-20px"
        animateOpacity
        marginTop="0"
        style={{
          marginTop: "0px !important",
          overflow: "initial",
        }}
        zIndex={show ? "1" : "0"}
        in={show}
      >
        <Flex
          zIndex={show ? "1" : "0"}
          w="100%"
          marginTop="3"
          marginBottom="3"
          direction={"column"}
          // display={!sliderVisible && 'none'}
        >
          <VStack
            spacing="3"
            alignItems="flex-end"
            // pb={!sliderVisible ? '15px' : '0'}
          >
            <Flex // AERODROMES
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Text
                opacity={show ? "1" : "0"}
                position="relative"
                transition="all ease 500ms"
                right="15px"
                top="5px"
                pointerEvents="none"
                fontSize="12px"
                letterSpacing="0.2px"
                textAlign="right"
                whiteSpace="nowrap"
                color="rgb(255, 255, 255)"
                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                fontWeight="500"
              >
                Aerodromes
              </Text>

              <AerodromeToggle show={show} />
            </Flex>

            <Flex // ADSB
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Text
                opacity={show ? "1" : "0"}
                position="relative"
                transition="all ease 500ms"
                right="15px"
                top="5px"
                pointerEvents="none"
                fontSize="12px"
                letterSpacing="0.2px"
                textAlign="right"
                whiteSpace="nowrap"
                color="rgb(255, 255, 255)"
                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                fontWeight="500"
              >
                ADSB sites
              </Text>
              <AdsbToggle show={show} />
            </Flex>

            <Flex // VHF
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Text
                opacity={show ? "1" : "0"}
                position="relative"
                transition="all ease 500ms"
                right="15px"
                top="5px"
                pointerEvents="none"
                fontSize="12px"
                letterSpacing="0.2px"
                textAlign="right"
                whiteSpace="nowrap"
                color="rgb(255, 255, 255)"
                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                fontWeight="500"
              >
                VHF sites
              </Text>
              <VhfToggle show={show} />
            </Flex>

            <Flex // NAVAID
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Text
                opacity={show ? "1" : "0"}
                position="relative"
                transition="all ease 500ms"
                right="15px"
                top="5px"
                pointerEvents="none"
                fontSize="12px"
                letterSpacing="0.2px"
                textAlign="right"
                whiteSpace="nowrap"
                color="rgb(255, 255, 255)"
                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                fontWeight="500"
              >
                NAVAID sites
              </Text>
              <NavaidToggle show={show} />
            </Flex>

            <Flex // FIXES
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Text
                opacity={show ? "1" : "0"}
                position="relative"
                transition="all ease 500ms"
                right="15px"
                top="5px"
                pointerEvents="none"
                fontSize="12px"
                letterSpacing="0.2px"
                textAlign="right"
                whiteSpace="nowrap"
                color="rgb(255, 255, 255)"
                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                fontWeight="500"
              >
                ROUTES and FIXES
              </Text>

              <FixesToggle show={show} />
            </Flex>

            <Flex // SECTORS
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Text
                opacity={show ? "1" : "0"}
                position="relative"
                transition="all ease 500ms"
                right="15px"
                top="5px"
                pointerEvents="none"
                fontSize="12px"
                letterSpacing="0.2px"
                textAlign="right"
                whiteSpace="nowrap"
                color="rgb(255, 255, 255)"
                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                fontWeight="500"
              >
                Atc Sectors
              </Text>

              <SectorToggle show={show} />
            </Flex>
          </VStack>
        </Flex>
      </SlideFade>
    </>
  );
}
