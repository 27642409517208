import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  Flex,
  useColorMode,
  Text,
  Spacer,
  useDisclosure,
  Modal,
  useColorModeValue,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Button,
  Icon,
  useBreakpointValue,
  Center,
  IconButton,
  Collapse,
} from "@chakra-ui/react";
import { ReactComponent as Aeropath } from "../images/aeropath.svg";
import { ReactComponent as Airways } from "../images/airwaysLogo.svg";
import SwitchesDesktop from "../map/controls/switchesDesktop";
import SwitchesMobile from "../map/controls/switchesMobile";
import { isMobileOnly, isIOS } from "react-device-detect";
import NavaidSwitches from "../map/controls/navaidSwitches";
import NavaidTypeSelector from "../map/controls/navaidTypeSelector";
import VhfSwitches from "../map/controls/vhfSwitches";
import AdsbSwitches from "../map/controls/adsbSwitches";
import { MdInfoOutline, MdLightMode, MdDarkMode } from "react-icons/md";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import {
  navaidListState,
  vhfListState,
  vhfSelectedState,
  navaidsSelectedState,
} from "../globalState";

export default function Nav() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { toggleColorMode } = useColorMode();
  const navaidList = useRecoilValue(navaidListState);
  const navaidsSelected = useRecoilValue(navaidsSelectedState);
  const vhfList = useRecoilValue(vhfListState);
  const vhfSelected = useRecoilValue(vhfSelectedState);
  const icon = useColorModeValue(<MdLightMode />, <MdDarkMode />);
  const [miscExpanded, setMiscExpanded] = useState(false);

  const mobileSwitches = useBreakpointValue({
    base: true,
    md: false,
  });
  const miscExpandedState = useBreakpointValue({
    base: miscExpanded,
    md: true,
  });

  useEffect(() => {
    if (!mobileSwitches) {
      setMiscExpanded(false);
    }
  }, [mobileSwitches, setMiscExpanded]);

  const buttonBg = useColorModeValue(
    "rgba(20, 50, 150, 0.05)",
    "rgba(255,255,255,0.07)"
  );
  const buttonBgHover = useColorModeValue(
    "rgba(20, 50, 150, 0.1)",
    "rgba(255,255,255,0.15)"
  );
  const buttonColor = useColorModeValue("gray.600", "rgba(255,255,255,0.5)");
  const buttonColorHover = useColorModeValue("gray.800", "rgba(255,255,255,1)");

  const collapseButtonHover = useColorModeValue("white", "gray.700");
  const collapseButtonHoverOpacity = useColorModeValue("0.9", "1");
  const collapseButtonBg = useColorModeValue("white", "gray.700");
  const collapseButtonColor = useColorModeValue("gray.700", "gray.200");
  const dropDownBg = useColorModeValue(
    "rgba(20,25,30, 0.15)",
    "rgba(20,25,30, 0.22)"
  );
  const mainButtonShadow = useColorModeValue(
    "0px 4px 12px -5px rgba(0, 0, 0, 0.3)",
    "0px 2px 20px -8px rgba(255,255,255,0.3)"
  );
  const mainButtonOpacity = useColorModeValue("1", "0.9");

  const noFocus = {
    _focus: { boxShadow: "none" },
    _focusVisible: { boxShadow: "none !important" },
  };

  // function to check if selectedNavaids have just VOR, or just DME, or both
  const navaidTypes = navaidList
    .filter((feat) => navaidsSelected.includes(feat.navid))
    .map((feat) => feat.type);

  const hasVOR = navaidTypes.includes("VOR");
  const hasDME = navaidTypes.includes("DME");
  let selectedNavaidTypes;
  if (hasVOR && hasDME) {
    selectedNavaidTypes = "vordme.500";
  } else if (hasVOR) {
    selectedNavaidTypes = "vor.600";
  } else if (hasDME) {
    selectedNavaidTypes = "dme.600";
  } else {
    selectedNavaidTypes = buttonColor;
  }

  const vhfTypes = vhfList
    .filter((feat) => vhfSelected.includes(feat.designator))
    .map((feat) => feat.type);

  const vhfHasCurrent = vhfTypes.includes("Current");
  const vhfHasProposed = vhfTypes.includes("Proposed");

  const isIphone = isMobileOnly && isIOS;

  return (
    <>
      <Flex
        height={{ base: isIphone ? "75px" : "60px", md: "100%" }}
        position="fixed"
        left="0"
        top={{ base: "unset", md: "0px" }}
        bottom={{ base: "0px", md: "unset" }}
        flexDirection="column"
        zIndex="1000"
        overflow="hidden"
        background={useColorModeValue("white", "gray.700")}
        width={{ base: "100%", md: "55px" }}
        boxShadow={useColorModeValue(
          "5px 0px 20px -2px rgba(0,0,0,0.1)",
          "5px 0px 20px -2px rgba(0,0,0,0)"
        )}
      >
        <Flex
          pt={{ base: "0px", md: "15px" }}
          zIndex="10"
          background={useColorModeValue("white", "gray.700")}
        >
          {mobileSwitches ? (
            <SwitchesMobile selectedNavaidTypes={selectedNavaidTypes} />
          ) : (
            <SwitchesDesktop selectedNavaidTypes={selectedNavaidTypes} />
          )}
        </Flex>

        <Spacer
          background={useColorModeValue("white", "gray.700")}
          zIndex="3"
        />

        <Flex
          direction={"column"}
          position={{ base: "fixed", md: "relative" }}
          borderRadius={{ base: "20px", md: "unset" }}
          justifyContent={"center"}
          w={{ base: "40px", md: "38px" }}
          top={{ base: "15px", md: "unset" }}
          left={{ base: "15px", md: "unset" }}
          width={{ base: "auto", md: "100%" }}
          zIndex={{ base: "1", md: "3" }}
          bgColor={{
            base: dropDownBg,
            md: useColorModeValue("white", "gray.700"),
          }}
          backdropFilter={{
            base: "blur(3px)",
            md: "none",
          }}
          boxShadow={{
            base: "inset 0px 0px 15px -7px rgba(0, 0, 0, 0.1)",
            md: "none",
          }}
        >
          {mobileSwitches && (
            <Center
              w="100%"
              h="40px"
              borderRadius={"20px"}
              boxShadow={mainButtonShadow}
            >
              <IconButton
                w="40px"
                h="40px"
                minW="40px"
                style={{ transition: "transform 0.2s" }}
                fontSize="1.6rem"
                borderRadius={"20px"}
                background={collapseButtonBg}
                color={collapseButtonColor}
                opacity={mainButtonOpacity}
                _hover={{
                  opacity: collapseButtonHoverOpacity,
                  backgroundColor: collapseButtonHover,
                }}
                _active={{
                  opacity: collapseButtonHoverOpacity,
                  backgroundColor: collapseButtonHover,
                }}
                icon={
                  miscExpanded ? (
                    <CloseIcon w={3} h={3} />
                  ) : (
                    <HamburgerIcon w={5} h={5} />
                  )
                }
                onClick={() => setMiscExpanded(!miscExpanded)}
                {...noFocus}
              />
            </Center>
          )}

          <Collapse
            animateOpacity
            in={miscExpandedState}
            style={{
              overflow: "initial",
            }}
            width="100%"
            overflow="hidden"
          >
            <Flex
              flexDirection="column"
              pt={{ base: "10px", md: "20px" }}
              pb={{ base: "5px", md: "20px" }}
              color={{ base: "rgba(255,255,255,1)", md: buttonColor }}
              alignItems="center"
              width="100%"
              gap="10px"
              overflow="hidden"
            >
              <Button
                background={{ base: "rgba(255,255,255,0.07)", md: buttonBg }}
                zIndex="110"
                padding="0px"
                margin="0px"
                height="32px"
                minHeight="unset"
                maxHeight="32px"
                width="32px"
                minWidth="unset"
                maxWidth="32px"
                borderRadius="16px"
                onClick={toggleColorMode}
                _hover={{
                  background: {
                    base: "rgba(255,255,255,0.15)",
                    md: buttonBgHover,
                  },
                  color: { base: "rgba(255,255,255,1)", md: buttonColorHover },
                  opacity: "1",
                }}
                _focus={{
                  background: {
                    base: "rgba(255,255,255,0.15)",
                    md: buttonBgHover,
                  },
                  color: { base: "rgba(255,255,255,1)", md: buttonColorHover },
                  opacity: "1",
                }}
                _active={{
                  background: {
                    base: buttonBgHover,
                    md: "rgba(255,255,255,0.15)",
                  },
                  color: { base: "rgba(255,255,255,1)", md: buttonColorHover },
                  opacity: "1",
                }}
              >
                {icon}
              </Button>
              <Button
                background={{ base: "rgba(255,255,255,0.07)", md: buttonBg }}
                zIndex="110"
                padding="0px"
                margin="0px"
                height="32px"
                minHeight="unset"
                maxHeight="32px"
                width="32px"
                minWidth="unset"
                maxWidth="32px"
                borderRadius="16px"
                onClick={onOpen}
                _hover={{
                  background: {
                    base: "rgba(255,255,255,0.15)",
                    md: buttonBgHover,
                  },
                  color: { base: "rgba(255,255,255,1)", md: buttonColorHover },
                  opacity: "1",
                }}
                _focus={{
                  background: {
                    base: "rgba(255,255,255,0.15)",
                    md: buttonBgHover,
                  },
                  color: { base: "rgba(255,255,255,1)", md: buttonColorHover },
                  opacity: "1",
                }}
                _active={{
                  background: {
                    base: buttonBgHover,
                    md: "rgba(255,255,255,0.15)",
                  },
                  color: { base: "rgba(255,255,255,1)", md: buttonColorHover },
                  opacity: "1",
                }}
              >
                <Icon as={MdInfoOutline} fontSize="1.2rem" />
              </Button>
            </Flex>
          </Collapse>
        </Flex>

        <Flex
          height={{ base: isIphone ? "75px" : "60px", md: "100%" }}
          position="absolute"
          left="0"
          top={{ base: "unset", md: "0px" }}
          bottom={{ base: "0px", md: "unset" }}
          flexDirection={{ base: "row", md: "column" }}
          zIndex="2"
        >
          <NavaidTypeSelector />
          <AdsbSwitches />
          <VhfSwitches
            vhfHasCurrent={vhfHasCurrent}
            vhfHasProposed={vhfHasProposed}
          />
          <NavaidSwitches hasVOR={hasVOR} hasDME={hasDME} />
        </Flex>
      </Flex>

      <Modal // ABOUT MODAL
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        trapFocus={false}
        variant="primary"
      >
        <ModalContent>
          <ModalHeader>Coverage Mapper Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack py="20px" width="100%" spacing="5px" textAlign="center">
              <Text>
                <span style={{ fontWeight: "700" }}>Coverage Data:</span>{" "}
                supplied by Airways Engineering.
              </Text>
              <Text
                fontStyle="italic"
                fontWeight="300"
                fontSize="0.8rem"
                pb="20px"
                opacity="0.8"
              >
                Coverage layers are estimates and suitable for general guidance
                only.
              </Text>
              <Text>
                Other aeonautical information and webmap supplied by Aeropath.
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Flex
              alignItems="center"
              justifyContent="space-evenly"
              width="100%"
            >
              <Aeropath width="125px" height="auto" />

              <Airways width="125px" height="auto" />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
