import React from "react";
import { Source, Layer } from "react-map-gl";
// import { vhfSelectedState } from "../../globalState";
// import { useRecoilValue } from "recoil";

export default function VhfSites() {
  // const vhfSelected = useRecoilValue(vhfSelectedState);
  const dataLayer = {
    id: "vhf_sites",
    beforeId: "vhf_sites_selected",
    type: "symbol",
    layout: {
      "icon-image": [
        "match",
        ["get", "type"],
        "Proposed",
        "VHF_proposed",
        "VHF",
      ],

      "icon-size": 0.8,

      // "icon-ignore-placement": true,
      // "text-allow-overlap": true,
      "text-field": [
        "match",
        ["get", "type"],
        "Proposed",
        ["concat", ["get", "name"], " (proposed site)"],
        ["get", "name"],
      ],
      "text-optional": true,
      "text-offset": [0, 2],
      "text-size": 11,
      "text-font": ["Montserrat SemiBold"],
      "symbol-sort-key": 1,
      "symbol-spacing": 1,
    },
    // filter:
    // [
    // "any",
    // ["match", ["get", "designator"], vhfSelected, false, true],
    // ["==", "type", "VHF"],
    // ["match", ["index-of", "designator", vhfSelected, 0], -1, true],
    // ["in", "", vhfSelected],
    // ],
    // filter: ["!", ["in", "designator", ...vhfSelected]],
    paint: {
      "text-color": "#000000",
      "text-halo-width": 1,
      "text-halo-color": "#ffffff",
      "text-opacity": ["interpolate", ["linear"], ["zoom"], 5, 0, 6, 1],
    },

    minzoom: 5,
  };

  return (
    <Source type="geojson" data={`${window.location.origin}/data/vhf_sites`}>
      <Layer {...dataLayer} />
    </Source>
  );
}
