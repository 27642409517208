import React, { useMemo, useCallback } from "react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedAltitudeState, layerState } from "../../globalState";

export default function AltSlider({ mapControlsExpanded }) {
  const layers = useRecoilValue(layerState);
  const [altitude, setAltitude] = useRecoilState(selectedAltitudeState);
  const allAltitudes = useMemo(
    () => [
      0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 15000,
      20000, 30000, 40000,
    ],
    []
  );
  // const lowAltitudes = useMemo(
  //   () => [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000],
  //   []
  // );
  const findClosestAltitude = useCallback(
    (altitude) => {
      const indexArr = allAltitudes.map(function (k) {
        return Math.abs(k - altitude);
      });
      const min = Math.min.apply(Math, indexArr);
      return allAltitudes[indexArr.indexOf(min)];
    },
    [allAltitudes]
  );

  // const findLowAltitude = useCallback(
  //   (altitude) => {
  //     const indexArr = lowAltitudes.map(function (k) {
  //       return Math.abs(k - altitude);
  //     });
  //     const min = Math.min.apply(Math, indexArr);
  //     return lowAltitudes[indexArr.indexOf(min)];
  //   },
  //   [lowAltitudes]
  // );

  // const onChangeStart={(altitude) => setAltitude(val)}
  // const onChangeEnd={(altitude) => setAltitude(val)}
  const [showTooltip, setShowTooltip] = React.useState(false);
  return (
    <Slider
      onChange={(val) => setAltitude(findClosestAltitude(val))}
      value={altitude}
      min={0}
      max={
        layers.vhf || layers.vhf_combined || layers.adsb_by_site || layers.adsb
          ? 40000
          : layers.navaid || layers.ssr
          ? 30000
          : 10000
      }
      // step={1000}
      orientation="vertical"
      minH="32"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() =>
        setTimeout(() => {
          setShowTooltip(false);
        }, 1500)
      }
    >
      <SliderMark
        value={0}
        color={layers.satellite ? "#b5b5b5" : "#292929"}
        mb="-7px"
        w="16"
        fontFamily="Montserrat, sans-serif"
        fontWeight="400"
        right="45px"
        textAlign="right"
      >
        GROUND
      </SliderMark>

      {/* <SliderMark value={1000} color="black" right="3px" mb="-2">
        --
      </SliderMark>
      <SliderMark value={2000} color="black" right="3px" mb="-2">
        --
      </SliderMark>
      <SliderMark value={3000} color="black" right="3px" mb="-2">
        --
      </SliderMark>
      <SliderMark value={4000} color="black" right="3px" mb="-2">
        --
      </SliderMark> */}
      <SliderMark
        value={5000}
        color={layers.satellite ? "#b5b5b5" : "#292929"}
        ml="4"
        mb="-10px"
        right="45px"
        fontFamily="Montserrat, sans-serif"
        fontWeight="400"
        w="16"
        zIndex="100"
        textAlign="right"
      >
        5000 ft
      </SliderMark>
      {/* <SliderMark value={6000} color="white" right="2px" mb="-2" zIndex="100">
        --
      </SliderMark>
      <SliderMark value={7000} color="white" right="2px" mb="-2" zIndex="100">
        --
      </SliderMark>
      <SliderMark value={8000} color="white" right="2px" mb="-2" zIndex="100">
        --
      </SliderMark>
      <SliderMark value={9000} color="white" right="2px" mb="-2" zIndex="100">
        --
      </SliderMark> */}
      <SliderMark
        value={10000}
        color={layers.satellite ? "#b5b5b5" : "#292929"}
        mb="-10px"
        w="16"
        fontFamily="Montserrat, sans-serif"
        fontWeight="400"
        right="45px"
        zIndex="100"
        textAlign="right"
      >
        10000 ft
      </SliderMark>
      {(layers.vhf ||
        layers.vhf_combined ||
        layers.adsb_by_site ||
        layers.adsb ||
        layers.navaid ||
        layers.ssr) && (
        <SliderMark
          value={15000}
          color={layers.satellite ? "#b5b5b5" : "#292929"}
          mb="-10px"
          w="16"
          fontFamily="Montserrat, sans-serif"
          fontWeight="400"
          right="45px"
          zIndex="100"
          textAlign="right"
        >
          15000 ft
        </SliderMark>
      )}
      {(layers.vhf ||
        layers.vhf_combined ||
        layers.adsb_by_site ||
        layers.adsb ||
        layers.navaid ||
        layers.ssr) && (
        <SliderMark
          value={20000}
          color={layers.satellite ? "#b5b5b5" : "#292929"}
          mb="-10px"
          w="16"
          fontFamily="Montserrat, sans-serif"
          fontWeight="400"
          right="45px"
          zIndex="100"
          textAlign="right"
        >
          20000 ft
        </SliderMark>
      )}
      {/* <SliderMark
        value={25000}
        color="#292929"
        
        mb="-10px"
        w="16"
        fontFamily="Montserrat, sans-serif"
        fontWeight="400"
        right="45px"
        zIndex="100"
        textAlign="right"
      >
        25000 ft
      </SliderMark> */}
      {(layers.vhf ||
        layers.vhf_combined ||
        layers.adsb_by_site ||
        layers.adsb ||
        layers.navaid ||
        layers.ssr) && (
        <SliderMark
          value={30000}
          color={layers.satellite ? "#b5b5b5" : "#292929"}
          mb="-10px"
          w="16"
          fontFamily="Montserrat, sans-serif"
          fontWeight="400"
          right="45px"
          zIndex="100"
          textAlign="right"
        >
          30000 ft
        </SliderMark>
      )}
      {(layers.vhf ||
        layers.vhf_combined ||
        layers.adsb_by_site ||
        layers.adsb) && (
        <SliderMark
          value={40000}
          color={layers.satellite ? "#b5b5b5" : "#292929"}
          mb="-10px"
          w="16"
          fontFamily="Montserrat, sans-serif"
          fontWeight="400"
          right="45px"
          zIndex="100"
          textAlign="right"
        >
          40000 ft
        </SliderMark>
      )}
      {/* <SliderMark
        value={altitude}
        textAlign="centre"
        fontFamily="Montserrat, sans-serif"
        fontWeight="400"
        color="white"
        bg="#29292970"
        mb="-10px"
        right="45px"
        w="55px"
        borderRadius="5px"
        textAlign="right"
      >
        {altitude} ft
      </SliderMark> */}
      <SliderTrack bg={"rgba(255, 255, 255, 0.3)"}>
        <SliderFilledTrack bg={useColorModeValue("white", "gray.700")} />
      </SliderTrack>
      <Tooltip
        // _hover={{ cursor: "pointer" }}
        display={
          (!mapControlsExpanded || (mapControlsExpanded && !showTooltip)) &&
          "none"
        }
        hasArrow
        borderRadius="7px"
        height="26px"
        fontSize="0.7rem"
        right="12px"
        bg="gray.600"
        color={altitude === 0 ? "#ff9f57" : "white"}
        fontFamily="Montserrat, sans-serif"
        fontWeight={altitude === 0 ? 600 : 400}
        placement="left"
        mb="2px"
        pt="5px"
        isOpen
        label={altitude === 0 ? `30 FT AGL` : `${altitude} FT AMSL`}
      >
        <SliderThumb bg={useColorModeValue("white", "gray.700")} zIndex="200" />
      </Tooltip>
    </Slider>
  );
}
