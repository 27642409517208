import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import "./App.css";
import Map from "./map";
import Nav from "./nav";
import {
  Flex,
  Button,
  Text,
  Center,
  Input,
  useBreakpointValue,
} from "@chakra-ui/react";
import loginImage from "./images/Radio Tower site.jpg";
import "overlayscrollbars/overlayscrollbars.css";
import { mapControlsExpandedState } from "./globalState";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const [password, setPassword] = useState("");
  const handlePasswordSubmit = () => {
    if (password === "batman") {
      setLoggedIn(true);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };
  const mapControlsExpanded = useRecoilValue(mapControlsExpandedState);

  useEffect(() => {
    // add class to body element
    if (!mapControlsExpanded) {
      document.body.classList.add("nav-hidden");
    } else {
      document.body.classList.remove("nav-hidden");
    }
  }, [mapControlsExpanded]);

  const bgPosition = useBreakpointValue({
    base: "-750px",
    xs: "-750px",
    sm: "0px",
  });

  if (!loggedIn) {
    return (
      <Flex
        className="login"
        width="100%"
        height="100vh"
        backgroundImage={`url("${loginImage}")`}
        backgroundSize="cover"
        backgroundPosition="top right"
        style={{
          backgroundPositionX: bgPosition,
        }}
      >
        <Center width="100%" height="100vh">
          <Flex
            px={{ base: "30px", sm: "40px" }}
            pt={{ base: "30px", sm: "40px" }}
            pb={{ base: "40px", sm: "60px" }}
            alignItems="start"
            flexDirection="column"
            gap="3"
            bg="rgba(255,255,255,0.2)"
            backdropFilter="blur(10px)"
            borderColor="rgba(10,20,30,0.15)"
            borderWidth="1px"
            borderRadius="30px"
            boxShadow="0px 15px 50px -20px rgba(10,20,30,0.5)"
          >
            <Flex
              width="100%"
              justifyContent="center"
              alignItems="baseline"
              fontFamily="Montserrat, sans-serif"
              fontWeight="300"
              fontSize={{ base: "2rem", xs: "2.3rem", sm: "2.6rem" }}
              textAlign="center"
              color="white"
              cursor="default"
              pb="20px"
              textShadow="1px 3px 15px rgba(0, 0, 0, 0.15)"
            >
              <Text fontWeight="300">Coverage</Text>
              <Text fontWeight="600">Mapper</Text>
            </Flex>
            <Text
              fontFamily="Montserrat, sans-serif"
              fontWeight="500"
              fontSize="0.9rem"
              color="gray.600"
              cursor="default"
            >
              Enter Password:
            </Text>
            <Flex alignItems="center" gap="2" width="100%">
              <Input
                variant="primary"
                type="password"
                bg="white"
                minWidth={{ base: "180px", xs: "200px", sm: "230px" }}
                width="100%"
                value={password}
                onChange={handlePasswordChange}
              />
              <Button
                variant="solid"
                background="cyan.400"
                _hover={{ background: "cyan.500" }}
                border="1px solid"
                borderColor="cyan.500"
                color="white"
                minWidth={{ base: "90px", xs: "100px", sm: "120px" }}
                borderRadius="12px"
                onClick={handlePasswordSubmit}
              >
                LOG IN
              </Button>
            </Flex>
          </Flex>
        </Center>
      </Flex>
    );
  } else {
    return (
      <div className="App" style={{ width: "100%", height: "100vh" }}>
        <Flex width="100%" height="100%">
          <Nav />
          <Map />
        </Flex>
      </div>
    );
  }
}

export default App;
